import { Injectable } from "@angular/core";
import { S1HttpClientService } from "@app/s1";
import { OrderProcess, OrderProcessAction } from "../interfaces/order-process.interface";
import { RequestBody } from "../interfaces/admin.interface";
import { Observable } from "rxjs";
import * as ENDPOINT from '../constants/endPoint.js';
import { getEndPointWithParams } from "../../../assets/js/util.js";
import { RestfulService } from "./restful.service";
import { HttpResponse } from "@angular/common/http";

export interface ChangeOrderStatusBody {
    id: number;
    idState: number;
}

export interface ChangeOrderPriority {
    id: number;
    priority: number;
}

export interface GeneratePickBody {
    idUdm: number;
    idProcessingOrderDetail: number;
    expectedPackages: number;
    idLocation: number;
}

@Injectable({providedIn: 'root'})
export class OrderProcessService {

    typeAction: OrderProcessAction;
    processToEdit: OrderProcess;
    fromDetail: boolean = false;

    constructor(
        private s1HttpService: S1HttpClientService,
        private restful: RestfulService
    ) { }

    /* GETTER AND SETTER TYPE ACTION */
    setTypeAction(typeAction: OrderProcessAction): void {
        this.typeAction = typeAction;
    }
    getTypeAction(): OrderProcessAction {
        return this.typeAction;
    }

    /* GETTER AND SETTER ITEM TO EDIT */
    setItemToEdit(order: OrderProcess): void {
        this.processToEdit = order;
    }
    getItemToEdit(): OrderProcess {
        return this.processToEdit;
    }

    /* GETTER AND SETTER FROM DETAIL INFO */
    setFromDetail(fromDetail: boolean): void {
        this.fromDetail = fromDetail;
    }
    getFromDetail(): boolean {
        return this.fromDetail;
    }

    /* ACTIONS */
    elementList(reqBody: RequestBody): Observable<any> {
        const endPoint = ENDPOINT.PICKING_ORDER_PROCESS_ALL;
        return this.s1HttpService.post(endPoint, reqBody, false);
    }

    loadElementDetail(reqBody: RequestBody, processId: number): Observable<any> {
        const endPoint = getEndPointWithParams(ENDPOINT.PICKING_ORDER_PROCESS_DETAIL, processId);
        return this.s1HttpService.post(endPoint, reqBody, false);
    }

    loadSingleElementDetailFull(processDetailId: number): Observable<any> {
        const endPoint = getEndPointWithParams(ENDPOINT.PICKING_ORDER_PROCESS_DETAIL_FULL, processDetailId);
        return this.s1HttpService.get(endPoint, false);
    }

    changeOrderStatus(reqBody: ChangeOrderStatusBody): Observable<any> {
        const endPoint = ENDPOINT.PICKING_ORDER_PROCESS_UPDATE_STATE;
        return this.s1HttpService.putWithBody(endPoint, reqBody, false);
    }

    changeOrderPriority(reqBody: ChangeOrderPriority): Observable<any> {
        const endPoint = ENDPOINT.PICKING_ORDER_PROCESS_CHANGE_PRIORITY;
        return this.s1HttpService.putWithBody(endPoint, reqBody, false);
    }

    syncVariation(reqBody: { id: number }): Observable<any> {
        const endPoint = ENDPOINT.PICKING_ORDER_PROCESS_SYNC_VARIATION;
        return this.s1HttpService.putWithBody(endPoint, reqBody, false);
    }

    exportOrderCSV(reqBody: RequestBody): Observable<any> {
        const endPoint = ENDPOINT.PICKING_ORDER_PROCESS_EXPORT_CSV;
        return this.s1HttpService.downloadPost(endPoint, reqBody, false);
    }

    exportAnomaliesCSV(reqBody: RequestBody): Observable<any> {
        const endPoint = ENDPOINT.PICKING_ORDER_PROCESS_EXPORT_ANOMALIES;
        return this.s1HttpService.downloadPost(endPoint, reqBody, false);
    }

    solveException(reqBody: { idMissionException: number }): Observable<any> {
        const endPoint = ENDPOINT.PROCESSING_EXCEPTION_SOLVE;
        return this.s1HttpService.putWithBody(endPoint, reqBody, false);
    }

    deleteException(reqBody: { idMissionException: number }): Observable<any> {
        const endPoint = ENDPOINT.PROCESSING_EXCEPTION_DELETE;
        return this.s1HttpService.putWithBody(endPoint, reqBody, false);
    }

    retryPicking(reqBody: { idMissionException: number }): Observable<any> {
        const endPoint = ENDPOINT.PROCESSING_EXCEPTION_RETRY_PICKING;
        return this.s1HttpService.putWithBody(endPoint, reqBody, false);
    }

    loadPalletList(reqBody: RequestBody): Observable<any> {
        const endPoint = ENDPOINT.PROCESSING_UDM_ALL;
        return this.s1HttpService.post(endPoint, reqBody, false);
    }

    generateUdmPick(reqBody: GeneratePickBody): Observable<any> {
        const endPoint = ENDPOINT.PROCESSING_UDM_PICK;
        return this.s1HttpService.post(endPoint, reqBody);
    }

    fileList(idElement: number, queryParams: string = null): Observable<any> {
        const endPoint = getEndPointWithParams(ENDPOINT.PICKING_ORDER_PROCESS_FILE_ALL, idElement) +
          (queryParams ? `?${queryParams}` : '');
        return this.s1HttpService.get(endPoint, false);
    }

    downloadFile(idFile: number): Observable<any> {
        const endPoint = getEndPointWithParams(ENDPOINT.PICKING_ORDER_PROCESS_FILE, idFile);
        return this.getDownload(endPoint, 'image/*');
    }

    removeFile(idElement: number, idFile: number): Observable<any> {
        const endPoint = getEndPointWithParams(ENDPOINT.QUALITY_CONTROL_UDM_FILE_DELETE, idElement, idFile);
        return this.s1HttpService.delete(endPoint);
    }

    getBarcodeListToBeSynced(idOrder: number): Observable<any> {
        const endPoint = getEndPointWithParams(ENDPOINT.PICKING_ORDER_PROCESS_ALL_SYNC_BARCODE, idOrder);
        return this.s1HttpService.get(endPoint);
    }

    syncBarcode(idUdm: number): Observable<any> {
        const endPoint = getEndPointWithParams(ENDPOINT.PICKING_ORDER_PROCESS_SYNC_BARCODE, idUdm);
        return this.s1HttpService.post(endPoint, null, false);
    }

    associateUdm(idUdm: number, idUdmHost: number): Observable<any> {
        const endPoint = getEndPointWithParams(ENDPOINT.PICKING_ORDER_PROCESS_ASSOCIATE_UDM, idUdm, idUdmHost);
        return this.s1HttpService.post(endPoint, null, false);
    }

    importBarcodes(idOrder: number): Observable<any> {
        const endPoint = getEndPointWithParams(ENDPOINT.PICKING_ORDER_PROCESS_IMPORT_BARCODES, idOrder);
        return this.s1HttpService.get(endPoint);
    }

    getProcessingRegistrations(reqBody: RequestBody, idOrderDetail:number): Observable<any> {
        const endPoint = getEndPointWithParams(ENDPOINT.PROCESSING_TIME_ORDER, idOrderDetail);
        return this.s1HttpService.post(endPoint, reqBody, false);
    }

    getProcessingActivityLogs(reqBody: RequestBody, idOrderDetail:number): Observable<any> {
        const endPoint = getEndPointWithParams(ENDPOINT.PICKING_ORDER_PROCESS_ASSOCIATED_UDM, idOrderDetail);
        return this.s1HttpService.post(endPoint, reqBody, false);
    }

    /* RESTFUL */

    getDownload(endPoint: string, contentType: string = 'application/json'): Observable<any> {
        return new Observable((observer) => {
          this.restful.get_downloadFile(endPoint, contentType).subscribe((response: HttpResponse<any>) => {
            observer.next(response);
          });
        });
    }

}
