import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-input-list',
  templateUrl: './input-list.component.html',
  styleUrls: ['./input-list.component.scss']
})
export class InputListComponent implements OnInit {

  @Input() inputControl: AbstractControl;
  @Input() inputLabel: string = '';
  @Input() inputType: string = 'text';
  @Input() listControl: AbstractControl;
  @Input() readonly: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  get inputCtrl() {
    return this.inputControl as FormControl;
  }

  get listCtrl() {
    return this.listControl as FormControl;
  }

  checkIsInputValid(): boolean {
    return this.inputCtrl?.valid &&
      (this.inputCtrl?.value != null && this.inputCtrl?.value != '');
  }

  addListValue() {
    if (this.inputCtrl?.value) {
      let listValues = this.listCtrl?.value;
      listValues?.push(this.inputCtrl?.value)
      this.listCtrl?.setValue(listValues);
      this.inputCtrl?.setValue(null);
    }
  }

  deleteListValue(index: number) {
    let listValues = this.listCtrl?.value;
    listValues?.splice(index, 1);
    this.listCtrl?.setValue(listValues);
  }

}
