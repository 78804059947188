import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SortableItem } from '@app/core/interfaces/core.interface';

@Component({
  selector: 'app-sortable',
  templateUrl: './sortable.component.html',
  styleUrls: ['./sortable.component.scss']
})
export class SortableComponent implements OnInit {

  @Input() dropZones: string[];
  @Input() sortableData: SortableItem[];
  @Input() dropEnabled: boolean = true;
  @Input() dragEnabled: boolean = true;
  @Output() onDragSuccess = new EventEmitter<any>();
  @Output() onDropSuccess = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onDrag($event) {
    this.onDragSuccess.emit($event);
  }

  onDrop($event) {
    this.onDropSuccess.emit($event);
  }
}
