import { Component, Input, OnInit } from '@angular/core';
import { DdtStatus } from '@app/core/interfaces/ddt.interface';

@Component({
  selector: 'app-ddt-state',
  templateUrl: './ddt-state.component.html',
  styleUrls: ['./ddt-state.component.scss']
})
export class DdtStateComponent implements OnInit {

  @Input() state: number = null;
  DdtStatus = DdtStatus;

  constructor() { }

  ngOnInit(): void {
  }

}
