import { ServerJobState } from '@app/core/interfaces/core.interface';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-job-state',
  templateUrl: './job-state.component.html',
  styleUrls: ['./job-state.component.scss']
})
export class JobStateComponent implements OnInit {

  @Input() state;
  State = ServerJobState;

  constructor() { }

  ngOnInit() {
  }

}
