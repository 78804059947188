import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// import config_file from '../../../assets/config/priviliges.json';
import { SettingsService } from '../settings/settings.service';
import { LoggingService } from './log.service';
import { RestfulService } from './restful.service';
import * as ENDPOINT from '../constants/endPoint.js';
import { Permission, Configuration, SessionMetadata } from '../interfaces/core.interface';
import { RequestBody } from '../interfaces/admin.interface';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  configurationFile: any = {
    permissions: [],
    configuration: {},
    admin: false,
  };

  configFileIsLoaded = false;

  roles: any;

  constructor(
    private logger: LoggingService,
    private settings: SettingsService,
    private restful: RestfulService
  ) {
    this.setConfigurationFile(this.configurationFile || null);
  }

  /**
   * Caricamento del file di configurazione da backend
   */
  loadConfigurationFile(): Observable<any> {
    this.configFileIsLoaded = false;
    const endPoint = ENDPOINT.CORE_LOAD_PRIVILEGES;
    return this.get(endPoint);
  }

  /**
   * Questo metodo setta il file di configurazione in locale
   */
  setConfigurationFile(configurationFile: any = null): void {
    this.configurationFile = JSON.parse(JSON.stringify(configurationFile));
    this.configFileIsLoaded = true;
  }

  setPermissions(permissions: Permission[]) {
    const permissionCodes = permissions.map(el => el.code);
    this.configurationFile.permissions = [].concat(permissionCodes);
  }

  setConfiguration(configuration: Configuration) {
    this.configurationFile.configuration = configuration;
  }

  setAdmin(admin: boolean) {
    this.configurationFile.admin = admin;
  }

  /**
   * Stato del caricamento del file di configurazione
   */
  getLoadingConfigFileStatus(): boolean {
    return this.configFileIsLoaded;
  }

  /**
   * Ritorna `true` nel caso in cui l'utente con ruolo `role` abbia il privilegio `privilege`
   * per visualizzare la sezione `sectionName`, `false` altrimenti
   */
  userHasPermission(permission: string): boolean {
    if (this.configFileIsLoaded) {
      if (this.configurationFile.permissions.includes(permission)) {
        return true;
      }
    }
    return false;
  }

  getConfigurationField(field: string): any {
    if (this.configFileIsLoaded) {
      if (this.configurationFile.configuration) {
        return this.configurationFile.configuration[field] ?? null;
      }
    }
    return null;
  }

  userIsAdmin(): boolean {
    if (this.configFileIsLoaded) {
      return this.configurationFile.admin;
    }
    return false;
  }

  getSessionMetadata(): Observable<any> {
    const endPoint = ENDPOINT.CORE_SESSION_METADATA;
    return this.get(endPoint);
  }

  saveSessionMetadata(reqBody: SessionMetadata): Observable<any> {
    const endPoint = ENDPOINT.CORE_SESSION_METADATA;
    return this.put(endPoint, reqBody);
  }

  /* RESTFUL METHOD */

  post(endPoint: string, reqBody: any, localhost: boolean = false): Observable<any> {
    return Observable.create((observer) => {
      this.restful.post(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  put(endPoint: string, reqBody: any): Observable<any> {
    return Observable.create((observer) => {
      this.restful.put(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  delete(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.delete(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  get(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.get(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  responseHandler(response: HttpResponse<any>, observer: any): void {
    const outcome = response['outcome'];
    if (outcome.success === true) {
      this.logger.log('Service:', 'SUCCESS', 200);
      observer.next(response);
    } else {
      this.logger.log('Service:', 'FAILURE', 200);
      outcome.message = this.settings.manageErrorMsg(outcome);
      observer.error(outcome);
    }
  }
}
