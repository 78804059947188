import { UserGroup, Permission, Configuration } from './core.interface';
export enum LogLevel {
  ALL = 100,
  DEBUG = 200,
  INFO = 300,
  PROD = 400,
}

export enum SelectionType {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}

export enum State {
  ALL = 2,
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum BlocksState {
  ALL = 2,
  WITHOUT_BLOCKS = 0,
  WITH_BLOCKS = 1,
}

export interface LoggedUserInfo {
  token: string;
  name: string;
  surname: string;
  namesurname?: string;
  enabled: boolean;
  role: string;
  admin: boolean;
  groups: UserGroup[];
  permissions: Permission[];
  configuration: Configuration;
}

export interface RequestBody {
  paging: boolean;
  page: number;
  rows: number;
  summary?: boolean;
  orderBy?: string;
  direction?: string;
  filters: EntityEnum;
}

export interface EntityEnum {
  BLOCK?: any;
  BUILDING?: any;
  COOPERATIVE?: any;
  JOB?: any;
  LAST_LOCATION?: any;
  LOCATION?: any;
  LOCATION_CLASS?: any;
  LOCATION_SIDE?: any;
  LOCATION_TYPE?: any;
  LOCATION_VIEW?: any;
  ORDER_ANOMALY?: any;
  ORDER_DETAIL?: any;
  ORDER_DDT?: any;
  ORDER_HEADER?: any;
  ORDER_HEADER_VIEW?: any;
  ORDER_DETAIL_HOST?: any;
  ORDER_DETAIL_VIEW?: any;
  ORDER_DDT_RECEIVE?: any;
  ORDER_DETAIL_PRODUCT_VIEW?: any;
  PRINTER?: any;
  PRODUCT?: any;
  PRODUCT_CALIBRE?: any;
  PRODUCT_HOST?: any;
  PRODUCT_ORIGIN?: any;
  PRODUCT_PROCESSING?: any;
  PRODUCT_QUALITY?: any;
  PRODUCT_SIZE?: any;
  PRODUCT_SPECIES?: any;
  PRODUCT_TYPOLOGY?: any;
  PRODUCT_VARIETY?: any;
  PRODUCT_PACKAGING?: any;
  PRODUCT_PACKAGING_LINE?: any;
  PRODUCT_MATERIAL?: any;
  PRODUCT_PACK?: any;
  PRODUCT_VIEW?: any;
  SITE?: any;
  USER?: any;
  VENDOR?: any;
  ZONE?: any;
  ZONE_TYPE?: any;
  SURVEY_TEMPLATE?: any;
  CUSTOMER?: any;
  PRODUCT_THRESHOLD?: any;
  CAUSAL?: any;
  PURPOSE?: any;
  UDM?: any;
  UDM_STATE?: any;
  UDM_CHECK?: any;
  UDM_BLOCK?: any;
  DIFFERENTIAL?: any;
  MAILING_LIST?: any;
  COMPANY?: any;
  MISSION_HEADER?: any;
  MISSION_TYPE?: any;
  MISSION_SCOPE?: any;
  USER_CURRENT?: any;
  PUTAWAY_RULE?: any;
  MISSION_STATE?: any;
  PRODUCT_PLATFORM?: any;
  ORDER_SALE_HEADER?: any;
  ORDER_SALE_HEADER_STATE?: any;
  ORDER_SALE_DETAIL?: any;
  MISSION_EXCEPTION?: any;
  ORDER_PROCESS_HEADER?: any;
  ORDER_PROCESS_HEADER_STATE?: any;
  ORDER_PROCESS_DETAIL?: any;
  PROCESSING_TYPE_FCC?: any;
  API_CALL_LOG?: any;
}

export enum PossibleActionsType {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  DUPLICIATE = 'DUPLICIATE',
  TOGGLE_STATE = 'TOGGLE_STATE',
}
export interface PossibleActions {
  edit?: boolean;
  delete?: boolean;
  duplicate?: boolean;
  disassociate?: boolean;
}

export interface CellConfiguration {
  title?: string;
  name?: string;
  key?: string;
  onChip?: boolean;
}

export interface SweetAlertMsg {
  loadingText?: string;
  creationText?: string;
  updatingText?: string;
  errorText?: string;
  firstError?: string;
  secondError?: string;
  warning?: string;
}

export interface ElementState {
  value: number;
  name: string;
}
