import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService } from '@app/core/services/log.service';
import { SettingsService } from '@app/core/settings/settings.service';
import { S1UIService } from '@app/s1';
import { S1ButtonType } from '@app/s1/components/s1-button/s1-button.component';
import { S1Modal, S1ModalSizes } from '@app/s1/components/s1-modal/s1-modal.component';
import { QCCheckService } from '@app/core/services/qc-check.service';
import { QCCheckAction } from '@app/core/interfaces/qc-check.interface';
import { StorageFile } from '@app/core/interfaces/storage.interface';
import { LogLevel, RequestBody } from '@app/core/interfaces/admin.interface';
import { OrderProcessAction, OrderProcessDetail } from '@app/core/interfaces/order-process.interface';
import { OrderProcessService } from '@app/core/services/order-process.service';
import { Direction, Multiselect, MultiselectItems, OrderByEntities } from '@app/core/interfaces/core.interface';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.scss']
})
export class PhotoGalleryComponent implements OnInit {

  @ViewChild('photoModal') photoModal: S1Modal;

  originSection: string;
  PREVIOUS_PATH: string;
  S1ButtonType = S1ButtonType;
  typeAction: QCCheckAction | OrderProcessAction;
  actualService: string;
  qcChecks: boolean = false;
  orderDetails: OrderProcessDetail[];
  Multiselect = Multiselect;
  /** Available action types */
  QCCheckAction = QCCheckAction;
  OrderProcessAction = OrderProcessAction;

  itemOnFocus = null;

  defaultSorting = {
    orderBy: OrderByEntities.ORDER_LINE_NO,
    direction: Direction.ASC,
  };

  requestBody: RequestBody = {
    paging: false,
    page: 0,
    rows: environment.rows,
    orderBy: this.defaultSorting.orderBy,
    direction: this.defaultSorting.direction,
    summary: true,
    filters: {
    }
  }

  processing = {
    detail: false,
    photoList: false,
    downloadPhotoList: [],
  };

  loaded = {
    downloadPhotoList: []
  }

  photoList: StorageFile[] = [];
  totalPhotos: number = 0;

  photoFileList: { [key: number]: string | ArrayBuffer | null } = [];
  
  photoIndex: number = null;

  filterGroup: FormGroup;

  multiselectItems: MultiselectItems = {
    orderDetail: []
  }

  constructor(
    private qcCheckService: QCCheckService,
    private orderProcessService: OrderProcessService,
    private ui: S1UIService,
    private settingService: SettingsService,
    private logService: LoggingService,
    private router: Router,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder
  ) {
    this.configurePageSettings();
    this.filterGroup = this.formBuilder.group({
      multiselect: this.formBuilder.group({
        orderDetail: [null]
      })
    });
  }

  ngOnInit(): void {
    switch (this.typeAction) {
      case QCCheckAction.VIEW_PHOTO:
      case OrderProcessAction.VIEW_PHOTO:
        this.loadDetail();
        break;
      default:
        this.navigateBack();
        break;
    }
  }

  loadDetail(showProcessing: boolean = true) {
    const idElement = this[this.actualService].getItemToEdit()?.id;
    if (idElement != null) {
      if (showProcessing) {
        this.processing.detail = true;
      }
      this[this.actualService].loadElementDetail(this.qcChecks ? idElement : this.requestBody, idElement).subscribe(
        (response) => {
          this.logService.log("[SUCCESS] Caricamento dettagli udm", response, LogLevel.DEBUG);
          const { data } = response;

          if (this.qcChecks) {
            this.itemOnFocus = JSON.parse(JSON.stringify(data));
          } else {
            this.orderDetails = data;
            this.multiselectItems[Multiselect.ORDER_DETAIL] = this.orderDetails.map(detail => {return { code: detail.id, label: detail.itemGroupDesc || '--' }});
            this.itemOnFocus = this.orderDetails[0];
            this.filterGroup.get('multiselect').get('orderDetail').patchValue(this.itemOnFocus.id);
          }
          
          this.loadPhotos(this.itemOnFocus.id);
          this.processing.detail = false;
        },
        (error) => {
          this.ui.showErrorToast(this.settingService.manageErrorMsg(error));
          this.processing.detail = false;
        })
    }
  }

  loadPhotos(idElement: number) {
    this.processing.photoList = true;
    const queryParams = 'type=image';
    this[this.actualService].fileList(idElement, queryParams).subscribe(
      (response) => {
        this.logService.log("[SUCCESS] Lista foto udm", response, LogLevel.DEBUG);
        const { data, total } = response;
        this.photoList = data && data.length > 0 ? data : [];
        this.totalPhotos = total;
        this.photoList.forEach(photo => { this.downloadPhoto(photo.id); })
        this.processing.photoList = false;
      },
      (error) => {
        this.logService.log("[ERROR] Lista foto udm", error, LogLevel.DEBUG);
        this.photoList = [];
        this.totalPhotos = 0;
        this.ui.showErrorToast(this.settingService.manageErrorMsg(error));
        this.processing.photoList = false;
      }
    );
  }

  downloadPhoto(idFile: number) {
    this.processing.downloadPhotoList[idFile] = true;
    this.loaded.downloadPhotoList[idFile] = false;
    this[this.actualService].downloadFile(idFile).subscribe(
      (response) => {
        this.logService.log("[SUCCESS] Download foto", response, LogLevel.DEBUG);
        this.createImageFromBlob(idFile, response);
        this.processing.downloadPhotoList[idFile] = false;
      },
      (error) => {
        this.logService.log("[ERROR] Download foto", error, LogLevel.DEBUG);
        this.processing.downloadPhotoList[idFile] = false;
      }
    );
  }

  createImageFromBlob(idFile: number, image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.photoFileList[idFile] = reader.result;
      this.loaded.downloadPhotoList[idFile] = true;
    }, false);
 
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  deletePhoto(element: StorageFile) {
    this.ui.showDialogPopup('s1.swal.delete_warn').then(el => {
      if (el.value) {
        this.onRemovePhoto(element);
      }
    });
  }

  onRemovePhoto(element: StorageFile) {
    const idElement = this[this.actualService].getItemToEdit()?.id;
    this[this.actualService].removeFile(idElement, element.id).subscribe(
      (response) => {
        this.logService.log("[SUCCESS] Eliminazione foto", response, LogLevel.DEBUG);
        this.ui.showSuccessToast('s1.toast.delete_success');
        this.loadDetail(false);
      },
      (error) => {
        this.logService.log("[ERROR] Eliminazione foto", error, LogLevel.DEBUG);
        this.ui.showErrorToast(this.settingService.manageErrorMsg(error));
      },
    );
  }

  navigateBack() {
    this.router.navigate([this.PREVIOUS_PATH]);
  }

  pageIsReady() {
    return !this.processing.detail;
  }

  openPhotoModal(index: number): void {
    this.photoIndex = index;
    this.photoModal.open(S1ModalSizes.LG);;
  }

  closePhotoModal(): void {
    this.photoModal.close();
  }

  showPreviousPhoto() {
    if (this.photoIndex == 0) {
      this.photoIndex = this.photoList.length - 1;
    } else {
      this.photoIndex -= 1;
    }
  }

  showNextPhoto() {
    if (this.photoIndex == this.photoList.length - 1) {
      this.photoIndex = 0;
    } else {
      this.photoIndex += 1;
    }
  }

  photoModalIsReady() {
    return this.photoIndex != null;
  }

  // --------- ORDER PROCESS SPECIFIC PUBLIC METHODS --------- //

  getHeaderTitle(): string {
    if (this.itemOnFocus) {
      return `${this.itemOnFocus.order.processingOrderNumber || '--'} - (${this.datePipe.transform(this.itemOnFocus.processingDate, 'dd/MM/yy') || '--'})`;
    }
  }

  applyFilter(): void {
    const { multiselect } = this.filterGroup.value;
    const { orderDetail } = multiselect;

    this.itemOnFocus = this.orderDetails.find(item => item.id == orderDetail);

    this.loadPhotos(this.itemOnFocus.id);
  }

  // --------- PRIVATE METHODS -------- //

  private configurePageSettings(): void {
    this.originSection = this.router.url.split('/')[1];
    this.PREVIOUS_PATH = `/${this.originSection}`;
    this.qcChecks = this.originSection === 'qc-checks' ? true : false;
    this.typeAction = this.qcChecks ? this.qcCheckService.getTypeAction() : this.orderProcessService.getTypeAction();
    this.actualService = this.qcChecks ? 'qcCheckService' : 'orderProcessService';
  }
}
