import { Component, OnInit, Input } from '@angular/core';
import { OrderMissionStatus } from '@app/core/interfaces/sale-order.interface';

@Component({
  selector: 'app-order-mission-state',
  templateUrl: './order-mission-state.component.html',
  styleUrls: ['./order-mission-state.component.scss']
})
export class OrderMissionStateComponent implements OnInit {

  @Input() state: number;
  @Input() stateLabel: string;
  State = OrderMissionStatus;

  constructor() { }

  ngOnInit() { }

}
