export function getEndPointWithParams(endPoint) {
  var args = Array.prototype.slice.call(arguments, 1);
  var count = -1;
  return endPoint.replace(/:[a-zA-Z?]+/g, (match) => {
    count += 1;
    return args[count] !== undefined ? args[count] : match;
  });
}

export function swapArrayPosition(arr, index1, index2) {
  const item1 = arr[index1];
  const item2 = arr[index2];
  arr[index1] = JSON.parse(JSON.stringify(item2));
  arr[index2] = JSON.parse(JSON.stringify(item1));
  return arr;
}

export function isSameDay(date1, date2 = new Date()) {
  const { day: date2_day, month: date2_month, year: date2_year } = getDateInfo(date1);
  const { day: date1_day, month: date1_month, year: date1_year } = getDateInfo(date2);

  return date1_day == date2_day &&
    date1_month == date2_month &&
    date1_year == date2_year;
}

export function isYesterday(date, today = new Date()) {
  const { day: today_day, month: today_month, year: today_year } = getDateInfo(today);
  const { day: date_day, month: date_month, year: date_year } = getDateInfo(date);

  return today_day - 1 == date_day &&
    today_month == date_month &&
    today_year == date_year;
}

export function oneWeekAgo() {
  const oneDay = 86400000;
  const today = new Date();
  const todayTs = today.getTime();
  return new Date(todayTs - oneDay * 7);
}

export function getDateInfo(date) {
  return {
    minutes: date.getMinutes(),
    hour: date.getHours(),
    day: date.getDate(),
    month: date.getMonth() +1,
    year: date.getFullYear()
  }
}

export function setEndOfTheDay(date) {
  let d = new Date(date);
  d.setHours(23);
  d.setMinutes(59);
  d.setSeconds(59);
  d.setMilliseconds(999);
  return d;
}

export function setStartOfTheDay(date) {
  let d = new Date(date);
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d;
}

export function resetSeconds(date) {
  let d = new Date(date);
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d;
}

export function roundValue(value) {
  return Math.round((value) * 1e2) / 1e2;
}

export function getNumericValue(el) {
  return el != null ? roundValue(el) : '?';
}