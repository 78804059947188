import { Multiselect, MultiselectItem } from './../interfaces/core.interface';
import { LocationAction, LocationInfo } from '../interfaces/location.interface';
import { UserInfo } from '@app/core/interfaces/user.interface';
import { LoggingService } from '@app/core/services/log.service';
import { HttpResponse } from '@angular/common/http';
import { AppService } from './base.service';
import { Injectable } from '@angular/core';
import { Observable, ObservableInput } from 'rxjs';
import { RequestBody } from '../interfaces/admin.interface';
import { User, UserAction } from '../interfaces/user.interface';
import * as ENDPOINT from "../constants/endPoint.js";
import { SettingsService } from '../settings/settings.service';
import { RestfulService } from './restful.service';
import { getEndPointWithParams } from "../../../assets/js/util.js";

@Injectable({ providedIn: "root" })
export class MultiselectService {

  constructor(
    private appService: AppService,
    private logger: LoggingService,
    private settings: SettingsService,
    private restful: RestfulService,
  ) { }

  /* ACTIONS */
  loadMultiselect(multiselectType: Multiselect, param1?: any): Observable<any> {
    let endPoint: string;
    switch (multiselectType) {
      case Multiselect.AVAILABLE_DOCK:
        endPoint = ENDPOINT.RECEIVMENT_MULTISELECT_AVAILABLE_DOCK;
        break;
      case Multiselect.BUILDING:
        endPoint = ENDPOINT.CORE_MULTISELECT_BUILDING;
        break;
      case Multiselect.DDT:
        endPoint = getEndPointWithParams(ENDPOINT.RECEIVMENT_MULTISELECT_DDT, param1);
        break;
      case Multiselect.ENTRY:
        endPoint = getEndPointWithParams(ENDPOINT.RECEIVMENT_MULTISELECT_ENTRY, param1);
        break;
      case Multiselect.LOCATION_ASSOCIATED_PRODUCT:
        endPoint = ENDPOINT.CORE_MULTISELECT_LOCATION_ASSOCIATED_PRODUCT;
        break;
      case Multiselect.LOCATION_CLASS:
        endPoint = ENDPOINT.CORE_MULTISELECT_LOCATION_CLASS;
        break;
      case Multiselect.LOCATION_TYPE:
        endPoint = ENDPOINT.CORE_MULTISELECT_LOCATION_TYPE;
        break;
      case Multiselect.LOCATION_SIDE:
        endPoint = ENDPOINT.CORE_MULTISELECT_LOCATIONS_SIDE;
        break;
      case Multiselect.LOT:
        endPoint = getEndPointWithParams(ENDPOINT.RECEIVMENT_MULTISELECT_LOT, param1);
        break;
      case Multiselect.PRINTER:
        endPoint = ENDPOINT.RECEIVMENT_MULTISELECT_PRINTER;
        break;
      case Multiselect.PRODUCT:
        endPoint = ENDPOINT.CORE_MULTISELECT_PRODUCTS;
        break;
      case Multiselect.PRODUCT_CALIBRE:
        endPoint = ENDPOINT.CORE_MULTISELECT_PRODUCT_CALIBRE;
        break;
      case Multiselect.PRODUCT_ORIGIN:
        endPoint = ENDPOINT.CORE_MULTISELECT_PRODUCT_ORIGIN;
        break;
      case Multiselect.PRODUCT_PROCESSING:
        endPoint = ENDPOINT.CORE_MULTISELECT_PRODUCT_PROCESSING;
        break;
      case Multiselect.PRODUCT_QUALITY:
        endPoint = ENDPOINT.CORE_MULTISELECT_PRODUCT_QUALITY;
        break;
      case Multiselect.PRODUCT_SIZE:
        endPoint = ENDPOINT.CORE_MULTISELECT_PRODUCT_SIZE;
        break;
      case Multiselect.PRODUCT_SPECIE:
        endPoint = ENDPOINT.CORE_MULTISELECT_PRODUCT_SPECIE;
        break;
      case Multiselect.PRODUCT_TYPOLOGY:
        endPoint = ENDPOINT.CORE_MULTISELECT_PRODUCT_TYPOLOGY;
        break;
      case Multiselect.PRODUCT_VARIETY:
        endPoint = ENDPOINT.CORE_MULTISELECT_PRODUCT_VARIETY;
        break;
      case Multiselect.PRODUCT_PACKAGING:
        endPoint = ENDPOINT.CORE_MULTISELECT_PRODUCT_PACKAGING;
        break;
      case Multiselect.PRODUCT_PACKAGING_LINE:
        endPoint = ENDPOINT.CORE_MULTISELECT_PRODUCT_PACKAGING_LINE;
        break;
      case Multiselect.PRODUCT_MATERIAL:
        endPoint = ENDPOINT.CORE_MULTISELECT_PRODUCT_MATERIAL;
        break;
      case Multiselect.PRODUCT_PACK:
        endPoint = ENDPOINT.CORE_MULTISELECT_PRODUCT_PACK;
        break;
      case Multiselect.SITE:
        endPoint = ENDPOINT.CORE_MULTISELECT_SITE;
        break;
      case Multiselect.VENDOR:
        endPoint = getEndPointWithParams(ENDPOINT.RECEIVMENT_MULTISELECT_VENDOR, param1);
        break;
      case Multiselect.ZONE_TYPE:
        endPoint = ENDPOINT.CORE_MULTISELECT_ZONE_TYPE;
        break;
      case Multiselect.SURVEY_INPUT_TYPE:
        endPoint = ENDPOINT.QUALITY_CONTROL_MULTISELECT_SURVEY_INPUT_TYPE;
        break;
      case Multiselect.SURVEY:
        endPoint = ENDPOINT.QUALITY_CONTROL_MULTISELECT_SURVEY;
        break;
      case Multiselect.PRODUCT_TYPOLOGY_WITHOUT_CUSTOMERS:
        endPoint = ENDPOINT.CORE_MULTISELECT_PRODUCT_TYPOLOGY_WITHOUT_CUSTOMERS;
        break;
      case Multiselect.CUSTOMER_FAVOURITE:
        endPoint = ENDPOINT.CORE_MULTISELECT_CUSTOMER_FAVOURITE;
        break;
      case Multiselect.DIFFERENTIAL:
        endPoint = ENDPOINT.QUALITY_CONTROL_MULTISELECT_DIFFERENTIAL;
        break;
      case Multiselect.CAUSAL:
        if (param1) {
          endPoint = getEndPointWithParams(ENDPOINT.CORE_MULTISELECT_CAUSAL_FOR_SITE, param1);
        } else {
          endPoint = ENDPOINT.CORE_MULTISELECT_CAUSAL;
        }
        break;
      case Multiselect.PURPOSE:
        if (param1) {
          endPoint = getEndPointWithParams(ENDPOINT.CORE_MULTISELECT_PURPOSE_FOR_SITE, param1);
        } else {
          endPoint = ENDPOINT.CORE_MULTISELECT_PURPOSE;
        }
        break;
      case Multiselect.QUALITY_CONTROL_UDM_STATE_ACTIVE:
        endPoint = ENDPOINT.QUALITY_CONTROL_MULTISELECT_UDM_STATE_ACTIVE;
        break;
      case Multiselect.COMPANY:
        endPoint = ENDPOINT.CORE_MULTISELECT_COMPANY;
        break;
      case Multiselect.REPORT_TYPE:
        endPoint = ENDPOINT.CORE_MULTISELECT_REPORT_TYPE;
        break;
      case Multiselect.PUTAWAY_UDM_STATE:
        endPoint = ENDPOINT.PUTAWAY_MULTISELECT_UDM_STATE;
        break;
      case Multiselect.PRODUCT_PLATFORM:
        endPoint = ENDPOINT.CORE_MULTISELECT_PRODUCT_PLATFORM;
        break;
      case Multiselect.MISSION_TYPE:
        endPoint = ENDPOINT.CORE_MULTISELECT_MISSION_TYPE;
        break;
      case Multiselect.MISSION_SCOPE:
        endPoint = ENDPOINT.CORE_MULTISELECT_MISSION_SCOPE;
        break;
      case Multiselect.MISSION_STATE:
        endPoint = ENDPOINT.CORE_MULTISELECT_MISSION_STATE;
        break;
      case Multiselect.STOCK_MOVEMENT_TYPE:
        endPoint = ENDPOINT.CORE_MULTISELECT_STOCK_MOVEMENT_TYPE;
        break;
      case Multiselect.LOCATION:
        endPoint = ENDPOINT.CORE_MULTISELECT_LOCATION;
        break;
      case Multiselect.ORDER_SALE_STATE:
          endPoint = ENDPOINT.CORE_MULTISELECT_ORDER_SALE_STATE;
          break;
      case Multiselect.ORDER_PROCESS_STATE:
        endPoint = ENDPOINT.CORE_MULTISELECT_ORDER_PROCESS_STATE;
        break;
    }
    return this.get(endPoint);
  }

  getItemsFromData(data): MultiselectItem[] {
    return data?.map((d, idx) => {
      return {
        code: d.id ? d.id : idx,
        label: d.name ? d.name : (d.description ? d.description : null),
      };
    });
  }

  /* RESTFUL */
  post(endPoint: string, reqBody: any): Observable<any> {
    return new Observable((observer) => {
      this.restful.post(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  get(endPoint: string): Observable<any> {
    return new Observable((observer) => {
      this.restful.get(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  put(endPoint: string, reqBody: any): Observable<any> {
    return new Observable((observer) => {
      this.restful.put(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  responseHandler(response: HttpResponse<any>, observer: any): void {
    const outcome = response['outcome'];
    if (outcome.success === true) {
      this.logger.log("Service:", "SUCCESS", 200);
      observer.next(response);
    } else {
      this.logger.log("Service:", "FAILURE", 200);
      outcome.message = this.settings.manageErrorMsg(outcome);
      observer.error(outcome);
    }
  }

}