import { AbstractControl } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 's1-input-textarea',
  templateUrl: './s1-input-textarea.component.html',
  styleUrls: ['./s1-input-textarea.component.scss']
})
export class S1InputTextarea implements OnInit {

  @Input() control: AbstractControl;
  // @Input() control: FormControl;
  @Input() label: string = '';
  @Input() hasAction: boolean = false;
  @Input() placeholder: string = null;
  @Input() hideLabel: boolean = false;
  @Input() readonly: boolean = false;
  @Input() type: string = 'text';
  @Input() isValid: boolean = true;
  @Input() min: number = 0;

  constructor() { }

  ngOnInit(): void { }

  getPlaceholder(): string {
    if (this.placeholder != null) {
      return this.placeholder;
    }
    return '';
  }

  checkIsValid(): boolean {

    return this.control?.valid || !this.control?.touched;

  }

  get ctrl() {
    return this.control as FormControl;
  }

}
