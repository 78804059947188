export enum Direction {
  ASC = 'ASC',
  DESC = 'DESC',
  NULL = ''
}

export enum OrderByEntities {
  USERNAME = 'username',
  BUSINESS_NAME = 'businessName',
  DATE_ASSIGN_LOCATION = 'dateAssignLocation',
  LABEL_DATE = 'labelDate',
  IDENTIFIER = 'identifier',
  ITEM_GROUP_DESC = 'itemGroupDesc',
  ORDER_NUMBER = 'orderNo',
  PRODUCT_DESCRIPTION = 'description',
  PROGRESS = 'progress',
  RECEIPT_DATE = 'receiptDate',
  RECEIVMENT_DATE = 'receivmentDate',
  NAME = 'name',
  CODE = 'code',
  CODE_HOST = 'codeHost',
  PRODUCT_SPECIES_DESCRIPTION = 'description',
  PRODUCT_TYPOLOGY_DESCRIPTION = 'description',
  TS_CREATED = 'tsCreated',
  DATE_TIME = 'dateTime',
  BARCODE = 'barcode',
  DIFFERENTIAL = 'differential',
  TS_EXECUTION = 'tsExecution',
  KEY = 'key',
  EXPORT = 'export',
  ID = 'id',
  TOTAL = 'total',
  TOTAL_DIFFERENTIAL = 'totalDifferential',
  CAUSAL_DESCRIPTION = 'description',
  PRIORITY = 'priority',
  PROCESSING_ORDER_NUMBER = 'processingOrderNumber',
  ORDER_LINE_NO = 'orderLineNo',
  DESCRIPTION = 'description',
  STATE = 'state',
  TS_CREATION = 'tsCreation'
}

export enum ServerJobState {
  PENDING = 'PENDING',
  READY = 'READY',
  OK = 'OK',
  KO = 'KO',
  EXPIRED = 'EXPIRED',
}

export enum ServerJobStateId {
  ALL = 0,
  OK = 1,
  KO = 2,
  PENDING = 3,
  READY = 4,
  EXPIRED = 5,
}

export enum ServerJobService {
  IMPORT_ITEMS = 'IMPORT_ITEMS',
  IMPORT_ORDERS = 'IMPORT_ORDERS',
  IMPORT_RECEIVINGS = 'IMPORT_RECEIVINGS',
  IMPORT_VENDOR = 'IMPORT_VENDOR',
  IMPORT_CUSTOMERS = 'IMPORT_CUSTOMERS',
  IMPORT_ORDERS_SALES = 'IMPORT_ORDERS_SALES',
  IMPORT_ORDER_PROCESS = 'IMPORT_ORDER_PROCESS',
  IMPORT_PROCESSING_TYPE_FCC = 'IMPORT_PROCESSING_TYPE_FCC'
}

export enum ServerJobServiceId {
  ALL = 0,
  IMPORT_ITEMS = 1,
  IMPORT_ORDERS = 2,
  IMPORT_VENDOR = 3,
  IMPORT_RECEIVINGS = 4,
  IMPORT_CUSTOMERS = 5,
  IMPORT_ORDERS_SALES = 6,
  IMPORT_ORDER_PROCESS = 7,
  IMPORT_PROCESSING_TYPE_FCC = 8
}

export enum ConfigurationField {
  PRODUCT_THRESHOLD_TIME_UNIT = 'productThresholdTimeUnit',
  PRODUCT_TYPOLOGY_DEFAULT_PRIORITY = 'productTypologyDefaultPriority',
  PRODUCT_TYPOLOGY_PRIORITY_ORDER_DIRECTION = 'productTypologyPriorityOrderDirection',
  UDM_STOCK_UNIT = 'udmStockUnit',
  UDM_MISSING_BARCODE = 'udmMissingBarcode',
}

export enum ConfigurationThresholdTimeUnit {
  DAY = 'day',
  HOUR = 'hour',
  MINUTE = 'minute',
}

export enum ConfigurationStockUnit {
  PIECE = 'piece',
  PACKAGE = 'package',
  PALLET = 'pallet',
}

export enum SectionName {
  CALIBRE = 'CALIBRE',
  DDT = 'DDT',
  DOCKS = 'DOCKS',
  DOCKS_ASSIGN = 'DOCKS_ASSIGN',
  HOME = 'HOME',
  ITEMS = 'ITEMS',
  LOCATIONS = 'LOCATIONS',
  ORDERS = 'ORDERS',
  ORIGIN = 'ORIGIN',
  PRINTERS = 'PRINTERS',
  PROCESSING = 'PROCESSING',
  PRODUCT_ORDER = 'PRODUCT_ORDER',
  QUALITY = 'QUALITY',
  RECEIVING = 'RECEIVING',
  SERVICE_QUEUE = 'SERVICE_QUEUE',
  SIZE = 'SIZE',
  SPECIE = 'SPECIE',
  TYPOLOGY = 'TYPOLOGY',
  USERS = 'USERS',
  VARIETY = 'VARIETY',
  VENDORS = 'VENDORS',
  ZONES = 'ZONES',
  SURVEY_TEMPLATES = 'SURVEY_TEMPLATES',
  CUSTOMER = 'CUSTOMER',
  PRODUCT_THRESHOLD = 'PRODUCT_THRESHOLD',
  PRODUCT_TYPOLOGY_CUSTOMER = 'PRODUCT_TYPOLOGY_CUSTOMER',
  CAUSAL = 'CAUSAL',
  PURPOSE = 'PURPOSE',
  QC_UDM = 'QC_UDM',
  MAILING_LIST = 'MAILING_LIST',
  REPORT = 'REPORT',
  PUTAWAY_UDM = 'PUTAWAY_UDM',
  PUTAWAY_RULES = 'PUTAWAY_RULES',
  PUTAWAY_AREAS = 'PUTAWAY_AREAS',
  PRODUCT_STOCK = 'PRODUCT_STOCK',
  LOCATION_STOCK = 'LOCATION_STOCK',
  PLATFORM = 'PLATFORM',
  SALE_ORDERS = 'SALE_ORDERS',
  PROCESSING_ORDERS = 'PROCESSING_ORDERS',
  PALLET_LIST = 'PALLET_LIST',
  PROCESSING_TYPES = 'PROCESSING_TYPES',
  API_CALL_LOG = 'API_CALL_LOG',
  SEARCH_ITEMS = 'SEARCH_ITEMS'
}

export enum Multiselect {
  AVAILABLE_DOCK = 'availableDock',
  BUILDING = 'building',
  DDT = 'ddt',
  ENTRY = 'entry',
  LOCATION_ASSOCIATED_PRODUCT = 'locationAssociatedProduct',
  LOCATION_CLASS = 'locationClass',
  LOCATION_TYPE = 'locationType',
  LOCATION_SIDE = 'locationSide',
  LOT = 'lot',
  PRINTER = 'printer',
  PRODUCT = 'product',
  PRODUCT_CALIBRE = 'productCalibre',
  PRODUCT_ORIGIN = 'productOrigin',
  PRODUCT_PROCESSING = 'productProcessing',
  PRODUCT_QUALITY = 'productQuality',
  PRODUCT_SIZE = 'productSize',
  PRODUCT_SPECIE = 'productSpecie',
  PRODUCT_TYPOLOGY = 'productTypology',
  PRODUCT_VARIETY = 'productVariety',
  PRODUCT_PACKAGING = 'productPackaging',
  PRODUCT_PACKAGING_LINE = 'productPackagingLine',
  PRODUCT_MATERIAL = 'productMaterial',
  PRODUCT_PACK = 'productPack',
  SITE = 'site',
  VENDOR = 'vendor',
  ZONE_TYPE = 'zoneType',
  SURVEY_INPUT_TYPE = 'surveyInputType',
  SURVEY = 'survey',
  PRODUCT_TYPOLOGY_WITHOUT_CUSTOMERS = 'productTypologyWithoutCustomers',
  CUSTOMER_FAVOURITE = 'customerFavourite',
  DIFFERENTIAL = 'differential',
  CAUSAL = 'causal',
  PURPOSE = 'purpose',
  QUALITY_CONTROL_UDM_STATE_ACTIVE = 'qualityControlUdmStateActive',
  COMPANY = 'company',
  REPORT_TYPE = 'reportType',
  PUTAWAY_UDM_STATE = 'putawayUdmState',
  PRODUCT_PLATFORM = 'productPlatform',
  MISSION_TYPE = 'missionType',
  MISSION_SCOPE = 'missionScope',
  MISSION_STATE = 'missionState',
  STOCK_MOVEMENT_TYPE = 'stockMovementType',
  LOCATION = 'location',
  ORDER_SALE_STATE = 'orderSaleState',
  ORDER_PROCESS_STATE = 'orderProcessState',
  ORDER_DETAIL = 'orderDetail',
  UDM = 'udm',
  UDM_HOST = 'udmHost'
}

export interface MultiselectItems {
  availableDock?: MultiselectItem[];
  building?: MultiselectItem[];
  ddt?: MultiselectItem[];
  entry?: MultiselectItem[];
  locationAssociatedProduct?: MultiselectItem[];
  locationClass?: MultiselectItem[];
  locationType?: MultiselectItem[];
  locationSide?: MultiselectItem[];
  lot?: MultiselectItem[];
  printer?: MultiselectItem[];
  product?: MultiselectItem[];
  productCalibre?: MultiselectItem[];
  productOrigin?: MultiselectItem[];
  productProcessing?: MultiselectItem[];
  productQuality?: MultiselectItem[];
  productSize?: MultiselectItem[];
  productSpecie?: MultiselectItem[];
  productTypology?: MultiselectItem[];
  productVariety?: MultiselectItem[];
  productPackaging?: MultiselectItem[];
  productPackagingLine?: MultiselectItem[];
  productMaterial?: MultiselectItem[];
  productPack?: MultiselectItem[];
  site?: MultiselectItem[];
  vendor?: MultiselectItem[];
  zoneType?: MultiselectItem[];
  surveyInputType?: MultiselectItem[];
  survey?: MultiselectItem[];
  productTypologyWithoutCustomers?: MultiselectItem[];
  customerFavourite?: MultiselectItem[];
  causal?: MultiselectItem[];
  purpose?: MultiselectItem[];
  differential?: MultiselectItem[];
  qualityControlUdmStateActive?: MultiselectItem[];
  company?: MultiselectItem[];
  reportType?: MultiselectItem[];
  putawayType?: MultiselectItem[];
  putawayUdmState?: MultiselectItem[];
  productPlatform?: MultiselectItem[];
  stockMovementType?: MultiselectItem[];
  orderSaleState?: MultiselectItem[];
  orderProcessState?: MultiselectItem[];
  orderDetail?: MultiselectItem[];
  udm?: MultiselectItem[];
  udmHost?: MultiselectItem[];
}

export interface MultiselectItem {
  id?: number;
  code: number | string;
  label: string;
  productCode?: string;
  productDescription?: string;
  colour?: string;
  name?: string;
  description?: string;
}

export interface SortableItem {
  id: number;
  value: string;
  metadata?: any;
}

export interface Company {
  id?: number;
  businessName?: string;
  address?: string;
  active?: boolean;
  tsCreated?: number;
}

export interface Cooperative {
  idCooperative: number;
  businessName: string;
}

export interface UserGroup {
  idGroup: number;
  codeRole: string;
  name: string;
}

export interface Permission {
  idPermission: number;
  idGroup: number;
  roleName: string;
  namePermission: string;
  code?: string;
}

export interface Configuration {
  productThresholdTimeUnit?: string;
}

export interface Description {
  id: number;
  description: string;
}

export interface CodeName {
  id?: number;
  code?: string | number;
  name?: string;
}

export interface PossibleAction {
  canEdit?: boolean;
  canDelete?: boolean;
}

export interface ServerJob {
  dateTime?: number;
  error?: string;
  id?: number;
  service?: ServerJobService;
  status?: ServerJobState;
}

export interface SessionMetadata {
  idSite: number;
  idRole: number;
}
