import { LoggedUserInfo } from './../../../core/interfaces/admin.interface';
import { AuthService } from './../../../core/services/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';

import { UserblockService } from './userblock.service';
import { MultiselectService } from '@app/core/services/multiselect.service';
import { CodeName, Multiselect, SessionMetadata } from '@app/core/interfaces/core.interface';
import { IS1InputSelectItem, S1ButtonType, S1Modal, S1ModalSizes, S1UIService } from '@app/s1';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfigurationService } from '@app/core/services/configuration.service';

@Component({
    selector: 'app-userblock',
    templateUrl: './userblock.component.html',
    styleUrls: ['./userblock.component.scss']
})
export class UserblockComponent implements OnInit {

    @ViewChild('siteModal') siteModal: S1Modal;

    S1ButtonType = S1ButtonType;
    user: any;
    loggedUserInfo: LoggedUserInfo = null;
    groupsName = '';

    sitesList: IS1InputSelectItem[] = [];
    siteGroup: FormGroup;
    currentSite: IS1InputSelectItem;
    currentMetadata: SessionMetadata;

    constructor(
        public userblockService: UserblockService,
        private authService: AuthService,
        private multiselectService: MultiselectService,
        private ui: S1UIService,
        private formBuilder: FormBuilder,
        private configurationService: ConfigurationService
    ) {
        this.loggedUserInfo = this.authService.getLoggedUserInfoFromLocalStorage();
        for (const [idx, group] of this.loggedUserInfo.groups.entries()) {
            this.groupsName = this.groupsName + group.name + (idx === this.loggedUserInfo.groups.length - 1 ? '' : ', ');
        }

        this.siteGroup = this.formBuilder.group({
            multiselect: this.formBuilder.group({
                idSite: [null, Validators.required]
            })
        });
    }

    ngOnInit() {
        if (this.loggedUserInfo.admin) {
            this.manageSAModal();
        }
    }

    userBlockIsVisible() {
        return this.userblockService.getVisibility();
    }

    openSiteModal() {
        this.siteModal.open(S1ModalSizes.MD);
    }

    saveMetadata() {
        this.siteModal.close();
        this.ui.showSpinner();
        this.currentMetadata.idSite = this.siteGroup.controls.multiselect.get('idSite').value;
        this.configurationService.saveSessionMetadata(this.currentMetadata).subscribe(
            _ => {
                this.ui.closeSpinner();
                this.manageSAModal();
            }, (error) => {
                this.ui.closeSpinner();
                this.ui.manageError(error, '[ERROR] Salvataggio metadata');
            }
        );
    }

    // ---------- PRIVATE METHODS ---------- //

    /** Method that loads the values to be displayed in sites multiselect & valorizes the current site value */
    private loadMulitselect(): void {
        this.multiselectService.loadMultiselect(Multiselect.SITE).subscribe(
            (response) => {
                this.sitesList = response.data.map((site: CodeName) => ({ code: site.id, label: site.name }));
                this.currentSite = this.sitesList.find(st => st.code === this.currentMetadata.idSite);
                this.siteGroup.controls.multiselect.get('idSite').patchValue(this.currentSite.code);
            }, (error) => {
                this.ui.manageError(error, '[ERROR] Caricamento multiselect sites');
            }
        );
    }

    /** Mehtod that downloads the currents metadata and loads the multiselect accordingly */
    private manageSAModal(): void {
        this.configurationService.getSessionMetadata().subscribe(
            (response) => {
                this.currentMetadata = response.data;
                this.loadMulitselect();
            }, (error) => {
                this.ui.manageError(error, '[ERROR] Caricamento multiselect sites');
            }
        );
    }

}
