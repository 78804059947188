import { CodeName } from "./core.interface";
import { ItemInfo, ItemPlatform } from "./item.interface";
import { Location } from "./location.interface";
import { User } from "./user.interface";
import { Customer } from "./customer.interface";
import { Vendor } from "./vendor.interface";
import { Purpose } from "./purpose.interface";
import { Causal } from "./causal.interface";
import { OrderReceive } from "./order.interface";
import { Differential } from "./differential.interface";
import { SurveyExecution } from "./survey-execution.interface";
import { Block } from "./block.interface";
import { MissionDetails } from "./putaway-missions.interface";

export enum UdmStatus {
	CREATED = 0,
	RECEIVED = 15,
	IN_STOCK = 30,
	ALLOCATED_IN_PROGRESS = 45,
	IN_PROGRESS = 50,
	PROCESSED = 55,
	ALLOCATED_SHIPPING = 60,
	COMPLETED = 90,
	CANCELLED = 105
}

export interface Udm {
	id?: number;
	barcode?: string;
	orderNo?: string;
	product?: ItemInfo;
	productPlatform?: ItemPlatform;
	packages?: number;
	packagesReal?: number;
	pieces?: number;
	piecesReal?: number;
	mediumNetWeight?: number;
	netWeight?: number;
	netWeightReal?: number;
	grossWeight?: number;
	grossWeightReal?: number;
	state?: UdmState;
	itemGroupCode?: string;
	previousLocation?: Location;
	actualLocation?: Location;
	nextLocation?: Location;
	site?: CodeName;
	purpose?: Purpose;
	lastCheck?: UdmCheck;
	orderDdtReceive?: OrderReceive;
	vendor?: Vendor;
	surveyExecution?: SurveyExecution;
	hasNoDifferential?: boolean;
	hasNoSurvey?: boolean;
	hasAnomaly?: boolean;
	errorStatus?: string;
	tsCreated?: number;
	tsLastModified?: number;
	numOfBlocks?: number;
	numOfBlocksPicking?: number;
	numOfBlocksPutaway?: number;
	numOfActiveMissions?: number;
	stockWait?: number;
	stockStart?: number;
	stockVariation?: number;
	stockOut?: number;
	stockAllocated?: number;
	stockGiveback?: number;
	stockBusy?: number;
	stockIn?: number;
	stock?: number;
	stockUnallocated?: number;
	blocks?: Block[];
	missionDetails?: MissionDetails[];
	customers?: Customer[];
	entryDescription?: string;
	lot?: string;
}

export interface UdmState {
  value?: number;
  description?: string;
}

export interface UdmCheck {
  id?: number;
	differential?: Differential;
	udm?: Udm;
	udms?: { id: number }[];
	user?: User;
	note?: string;
	purpose?: Purpose;
	causal?: Causal;
	metadata?: string;
	errorStatus?: string;
	tsCreated?: number;
	customers?: Customer[];
}

export interface UdmBlockAssign {
  id?: number;
  blocks?: Block[];
}

export interface UdmThresholdVerify extends Udm {
	threshold?: number;
	overThreshold?: number;
}

export interface UdmStockUpdate {
	idUdm?: number;
	idMissionDetail?: number;
	stockMovementType?: CodeName;
	quantity?: number;
}

export interface UdmStockCorrection {
	idUdm?: number;
	stockQuantity?: number;
	idLocation?: number;
}

export interface UdmReturnedRequest{
	idSourceUdm?: number;
	returnedPackages?: number;
}

export interface UdmProductPlatformUpdate {
	id?: number;
}

export interface UdmVolume {
  zones: number;
  locations: number;
  maxPallets: number;
}

export interface UdmHost {
	id?: number;
	barcode?: string;
	orderNo?: string;
	product?: ItemInfo;
	packages?: number;
	packagesReal?: number;
  pieces?: number;
	piecesReal?: number;
	mediumNetWeight?: number;
	netWeight?: number;
	netWeightReal?: number;
	grossWeight?: number;
	grossWeightReal?: number;
	state?: UdmState;
	itemGroupCode?: string;
	hasAnomaly?: boolean;
  tsCreated?: number;
	tsLastModified?: number;
	stockWait?: number;
	stockStart?: number;
	stockVariation?: number;
	stockOut?: number;
	stockAllocated?: number;
	stockGiveback?: number;
	stockBusy?: number;
	stockIn?: number;
	udm?: Udm;
}
