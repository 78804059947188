import { Component, OnInit, Input } from '@angular/core';
import { UdmStatus } from '@app/core/interfaces/udm.interface';

@Component({
  selector: 'app-order-udm-state',
  templateUrl: './order-udm-state.component.html',
  styleUrls: ['./order-udm-state.component.scss']
})
export class OrderUdmStateComponent implements OnInit {

  @Input() state: number;
  State = UdmStatus;

  constructor() { }

  ngOnInit() { }

}
