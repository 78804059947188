import { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { LoggingService } from './log.service';
import { throwError } from 'rxjs';
import { SettingsService } from '../settings/settings.service';
import { SectionName } from '../interfaces/core.interface';
import { S1UIService } from '@app/s1';
import { TranslateService } from '@ngx-translate/core';
import { LogLevel, State } from '@app/core/interfaces/admin.interface';
import swal from 'sweetalert2';
import { VendorStatus } from '../interfaces/vendor.interface';

@Injectable()
export class AppService {

  public paging = true;
  public itemsPerPage = environment.rows; // server pagination
  public page: number = 1;
  public numPages = 1;
  public totalItems = 1;

  private filtersGroups = {};

  constructor(
    private http: HttpClient,
    private logger: LoggingService,
    private router: Router,
    private settings: SettingsService,
    private authService: AuthService,
    private ui: S1UIService,
    private translate: TranslateService
  ) { }

  setFilterOfSection(sectionName: SectionName, filterGroup: FormGroup): void {
    this.filtersGroups[sectionName] = Object.assign({}, filterGroup);
  }

  getFilterOfSection(sectionName: SectionName): FormGroup {
    return this.filtersGroups[sectionName];
  }

  // Rest Items List Service
  getList(path, filters) {
    // stringa per chiamata da fare
    let queryStr = "";
    let i = 0;
    let stringAmp = "";
    // imposto i parametri del filtro
    if (filters != null) {
      Object.keys(filters).forEach(function (key) {
        if (filters[key] != null && filters[key].toString() !== "") {
          if (i > 0) { stringAmp = "&"; }
          queryStr = queryStr + stringAmp + key + "=" + filters[key];
          i++;
        }
      });
    }

    // creo tutta la chiamata completa di paginazione e righe
    queryStr = environment.restBaseUrl
      + path + "?" + queryStr
      + "&paging=" + this.paging
      + "&page=" + this.page
      + "&rows=" + this.itemsPerPage;


    // clean url
    queryStr = queryStr.replace("?&", "?");

    this.logger.log("Chiamata URL lista:" + queryStr, "", 200);

    return this.http
      .get<any[]>(queryStr, this.getRequestOptionArgs())
      .pipe(map(
        (response: HttpResponse<any>) => {
          const outcome = response['outcome'];
          const data = response['data'];
          this.totalItems = data['total'];

          if (outcome.success === true) {
            this.logger.log("Service:", "SUCCESS", 200);
            return data;
          } else {
            this.logger.log("Service:", "FAILURE", 200);
            outcome.message = this.settings.manageErrorMsg(outcome);
            return outcome;
          }
        }
      ), catchError((errorResponse: HttpErrorResponse) => {
        const res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
      ));
  }

  // Rest Items Service: Read one element (detail)
  getElement(path) {
    path = environment.restBaseUrl + path;
    return this.http
      .get<any[]>(path, this.getRequestOptionArgs())
      .pipe(map(
        (response: HttpResponse<any>) => {
          const outcome = response['outcome'];
          const dataResponse = response['data'];
          this.totalItems = dataResponse['total'];
          if (outcome.success === true) {
            this.logger.log("Service:", "SUCCESS", 200);
            return dataResponse;
          } else {
            this.logger.log("Service:", "FAILURE", 200);
            outcome.message = this.settings.manageErrorMsg(outcome);
            return outcome;
          }
        }
      ), catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
      ));
  }

  // Rest Items Service: Read all REST Items
  getAll(path) {
    path = environment.restBaseUrl + path;
    return this.http
      .get<any[]>(path, this.getRequestOptionArgs())
      .pipe(map(
        (response: HttpResponse<any>) => {
          const outcome = response['outcome'];
          const dataResponse = response['data'];
          this.totalItems = dataResponse['total'];
          if (outcome.success === true) {
            this.logger.log("Service:", "SUCCESS", 200);
            return dataResponse;
          } else {
            this.logger.log("Service:", "FAILURE", 200);
            outcome.message = this.settings.manageErrorMsg(outcome);
            return outcome;
          }
        }
      ), catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
      ));
  }

  // get element list by data passed
  getAllFromData(path, requestData): any {
    path = environment.restBaseUrl + path;
    return this.http
      .post<any[]>(path, requestData, this.getRequestOptionArgs())
      .pipe(map(
        (response: HttpResponse<any>) => {
          const outcome = response['outcome'];
          const dataResponse = response['data'];
          if (outcome.success === true) {
            this.logger.log("Service:", "SUCCESS", 200);
            return dataResponse;
          } else {
            this.logger.log("Service:", "FAILURE", 200);
            outcome.message = this.settings.manageErrorMsg(outcome);
            return outcome;
          }
        }
      ), catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        let res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
      ));
  }

  // creation of new element
  newElement(path, elementRequest): any {
    path = environment.restBaseUrl + path;
    return this.http
      .post<any[]>(path, elementRequest, this.getRequestOptionArgs())
      .pipe(map(
        (response: HttpResponse<any>) => {
          const outcome = response['outcome'];
          const dataResponse = response['data'];
          if (outcome.success === true) {
            this.logger.log("Service:", "SUCCESS", 200);
            return response;
          } else {
            this.logger.log("Service:", "FAILURE", 200);
            outcome.message = this.settings.manageErrorMsg(outcome);
            return outcome;
          }
        }
      ), catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        let res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
      ));
  }

  // modify of an element
  editElement(path, elementRequest): any {
    path = environment.restBaseUrl + path;
    return this.http
      .put<any[]>(path, elementRequest, this.getRequestOptionArgs())
      .pipe(map(
        (response: HttpResponse<any>) => {
          const outcome = response['outcome'];
          const dataResponse = response['data'];
          if (outcome.success === true) {
            this.logger.log("Service:", "SUCCESS", 200);
            return response;
          } else {
            this.logger.log("Service:", "FAILURE", 200);
            outcome.message = this.settings.manageErrorMsg(outcome);
            return outcome;
          }
        }
      ), catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        let res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
      ));
  }

  // modify of an element
  deleteElement(path, elementRequest): any {
    path = environment.restBaseUrl + path;
    return this.http
      .put<any[]>(path, elementRequest, this.getRequestOptionArgs())
      .pipe(map(
        (response: HttpResponse<any>) => {
          const outcome = response['outcome'];
          const dataResponse = response['data'];
          if (outcome.success === true) {
            this.logger.log("Service:", "SUCCESS", 200);
            return response;
          } else {
            this.logger.log("Service:", "FAILURE", 200);
            outcome.message = this.settings.manageErrorMsg(outcome);
            return outcome;
          }
        }
      ), catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        let res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
      ));
  }

  invite(idUser: number): any {
    const path = environment.restBaseUrl + '/admin/user/invite/' + idUser;
    const elementRequest: any = {};

    // TODO: inserire la chiamata al servizio corretto!
    return this.http
      .put<any[]>(path, elementRequest, this.getRequestOptionArgs())
      .pipe(map(
        (response: HttpResponse<any>) => {
          const outcome = response['outcome'];
          const dataResponse = response['data'];
          if (outcome.success === true) {
            this.logger.log("Service:", "SUCCESS", 200);
            return response;
          } else {
            this.logger.log("Service:", "FAILURE", 200);
            outcome.message = this.settings.manageErrorMsg(outcome);
            return outcome;
          }
        }
      ), catchError((errorResponse: HttpErrorResponse) => {
        this.logger.log("Error Response:", errorResponse, 200);
        let res: any = errorResponse.error;
        if (res.outcome.code === "0005" || res.outcome.code === "0007") {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
      ));
  }

  getRequestOptionArgs(): any {
    console.log("getRequestOptions");

    const loggedUserInfo = this.authService.getLoggedUserInfoFromLocalStorage();
    let token = null;
    if (loggedUserInfo) {
      token = loggedUserInfo.token;
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token != null ? token : '',
        'X-Auth-Token': token != null ? token : ''
      })
    };
    return httpOptions;
  }

  /** Function required to create / update an entity with response management (to be called in component services)
   *
   * @param element - object entry to be created
   * @param path - API url
   * @param msg - msg displayed on success / error identifying the entity
   * @param previousPath - previous page path to be redirected to after success
   */
  crud(isCreation: boolean, element: any, path: string, msg: string, previousPath: string): void {
    this[isCreation ? 'newElement' : 'editElement'](path, element).subscribe(
      (response) => {
        if (response.outcome ? response.outcome.success : response.success) {
          this.logger.log(`[SUCCESS] ${msg}`, response, LogLevel.DEBUG);
          isCreation ? this.ui.showDialogCreateSuccess() : this.ui.showDialogEditSuccess();
          this.router.navigate([previousPath]);
        } else {
          this.logger.log(`[ERROR] ${msg}`, response.message, LogLevel.DEBUG);
          this.ui.closeSpinner();
          swal.fire(this.translate.instant('error.error'), response.message, 'error');
        }
      },
      (error: Error) => {
        this.logger.log(`[ERROR] ${msg}`, error, LogLevel.DEBUG);
        this.ui.closeSpinner();
        swal.fire(this.settings.manageErrorMsg(error), this.translate.instant('modal.edit_error'), 'error');
      }
    );
  }

  status2State(vendorStatus: string): State {
    switch (vendorStatus) {
      case VendorStatus.DEPLOYED:
        return State.ACTIVE;
      case VendorStatus.WITHDRAWN:
        return State.INACTIVE;
      default:
        return State.ALL;
    }
  }
}

