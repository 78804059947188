import { LoggedUserInfo, LogLevel } from './../../../core/interfaces/admin.interface';
import { IS1InputSelectItem } from './../../../s1/components/s1-input-select/s1-input-select.component';
import { UserGroup, Permission, Multiselect, CodeName } from './../../../core/interfaces/core.interface';
import { S1ButtonType } from './../../../s1/components/s1-button/s1-button.component';
import { S1Modal, S1ModalSizes } from './../../../s1/components/s1-modal/s1-modal.component';
import { ConfigurationService } from './../../../core/services/configuration.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { LoginData } from '../../../../app/core/classes/loginData.module';

import { LoggingService } from '../../../../app/core/services/log.service';
import { AuthService } from '../../../../app/core/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import swal from 'sweetalert2';
import { S1UIService } from '@app/s1';
import { MultiselectService } from '@app/core/services/multiselect.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  @ViewChild('metadataModal') metadataModal: S1Modal;

  S1ButtonType = S1ButtonType;
  loginForm: FormGroup;
  formUserGroup: FormGroup;
  dataResponse: LoggedUserInfo = null;
  loggedUserInfoLocalStorageKey = 'loggedUserInfo';
  rememberMeLocalStorageKey = 'rememberMe';
  userGroupItems: IS1InputSelectItem[] = [];
  originalGroups: UserGroup[] = [];
  originalPermissions: Permission[] = [];
  sitesList: IS1InputSelectItem[] = [];

  // params from path
  paramId;

  // string for errorMessage service
  msgTranslation;
  errorTranslation;
  errorMessage;

  constructor(
    public settings: SettingsService,
    private formBuilder: FormBuilder,
    private logger: LoggingService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private translate: TranslateService,
    private configurationService: ConfigurationService,
    private ui: S1UIService,
    private multiselectService: MultiselectService
  ) {

    // get text message
    this.translate.get('error').subscribe((text) => {
      this.errorTranslation = text;
    });

    this.translate.get('msg').subscribe((text) => {
      this.msgTranslation = text;
    });

    this.paramId = this.route.params.subscribe(params => {
      const id = params.id; // (+) converts string 'id' to a number
      if (id != null && this.msgTranslation) { this.errorMessage = this.msgTranslation.logout_ok; }
    });

    this.loginForm = this.formBuilder.group({
      username: [this.getRememberMeInfo() != null ? this.getRememberMeInfo() : null , Validators.required],
      password: [null, Validators.required],
      account_remember: [this.getRememberMeInfo() === null ? false : true, null],
      // 'username': ['PEV_TEST', Validators.required],
      // 'password': ['test', Validators.required],
    });

    this.formUserGroup = this.formBuilder.group({
      multiselect: this.formBuilder.group({
        idRole: [null, Validators.required],
        idSite: [null]
      })
    });

  }

  onSubmit(): void {
    const lData = new LoginData(this.loginForm.value.username, this.loginForm.value.password);
    this.ui.showSpinner();

    this.authService.login(lData).subscribe(
      (response) => {
        if (response.outcome.success === true) {
          this.setRememberMeInfo(this.loginForm);

          this.dataResponse = Object.assign({}, response.data);
          this.checkGroupsCount();
        } else {
          swal.fire(this.msgTranslation.error, this.settings.manageErrorMsg(response.outcome), 'error');
        }
      },
      (error) => {
        this.logger.log('Error', error, 200);
        this.errorMessage = this.errorTranslation.generic_error + ' ' + this.errorTranslation.try_again;
        swal.fire(this.msgTranslation.error, this.errorMessage, 'error');
      }
    );
  }

  ngOnInit() { }

  checkGroupsCount(): void {
    const { groups, permissions } = this.dataResponse;
    this.originalGroups = [...groups];
    this.originalPermissions = [...permissions];

    this.userGroupItems = [];
    for (const g of this.originalGroups) {
      this.userGroupItems.push({ code: g.idGroup, label: g.name });
    }

    // Only for SA user idSite select has to be mandatory
    if (this.dataResponse.admin) {
      this.formUserGroup.controls.multiselect.get('idSite').setValidators(Validators.required);
    }

    const multiselect = { idRole: this.userGroupItems[0].code };
    this.formUserGroup.patchValue({ multiselect }, { emitEvent: false });
    this.openMetadataModal();
  }

  onConfirmChoice(): void {
    const { multiselect } = this.formUserGroup.value;
    const { idRole } = multiselect;
    const groupSelected: UserGroup[] = this.originalGroups.filter(el => el.idGroup === idRole);
    const validPermission: Permission[] = this.originalPermissions.filter(el => el.idGroup === idRole);

    this.dataResponse.groups = [].concat(groupSelected);
    this.dataResponse.permissions = [].concat(validPermission);

    this.saveLoggedUserInfo();
    this.saveMetadata();
  }

  saveLoggedUserInfo(): void {
    localStorage.setItem(this.loggedUserInfoLocalStorageKey, JSON.stringify(this.dataResponse));
    this.configurationService.setPermissions(this.dataResponse.permissions);
    this.configurationService.setConfiguration(this.dataResponse.configuration);
    this.configurationService.setAdmin(this.dataResponse.admin);
  }

  setRememberMeInfo(loginForm): void {
    // check sul ricordami per salvare la username nel localstorage
    if (loginForm.value.account_remember === true) {
      localStorage.setItem(this.rememberMeLocalStorageKey, loginForm.value.username);
    } else {
      localStorage.removeItem(this.rememberMeLocalStorageKey);
    }
  }

  getRememberMeInfo(){
    return localStorage.getItem(this.rememberMeLocalStorageKey) != null ? localStorage.getItem(this.rememberMeLocalStorageKey) : null;
  }

  openMetadataModal() {
    if (this.dataResponse.admin) {
      this.manageSAModal();
    } else {
      this.ui.closeSpinner();
      this.metadataModal.open(S1ModalSizes.MD);
    }
  }

  closeMetadataModal() {
    this.metadataModal.close();
  }

  navigateToHome() {
    this.router.navigate(['/home']);
  }

  checkSAUser() {
    return this.dataResponse && this.dataResponse.admin;
  }

  // ---------- PRIVATE METHODS ---------- //

  /**
   * On modal opening for SA users sites multiselect data must be loaded
   * temporarily setting localStorage user (in order to fire multiselect service)
   */
  private manageSAModal() {
    this.saveLoggedUserInfo();
    this.multiselectService.loadMultiselect(Multiselect.SITE).subscribe(
      (response) => {
        this.sitesList = response.data.map((site: CodeName) => ({ code: site.id, label: site.name }));
        this.formUserGroup.controls.multiselect.get('idSite').patchValue(this.sitesList[0].code);
        this.ui.closeSpinner();
        this.metadataModal.open(S1ModalSizes.MD);
        localStorage.removeItem(this.loggedUserInfoLocalStorageKey);
      }, (error) => {
        this.ui.closeSpinner();
        this.ui.manageError(error, '[ERROR] Caricamento multiselect sites');
        localStorage.removeItem(this.loggedUserInfoLocalStorageKey);
      }
    );
  }

  /** Method that saves metadata information in order to filter the app depending on the selected value */
  private saveMetadata(): void {
    this.ui.showSpinner();
    this.configurationService.saveSessionMetadata(this.formUserGroup.controls.multiselect.value).subscribe(
      _ => {
        this.ui.closeSpinner();
        this.closeMetadataModal();
        this.navigateToHome();
      }, (error) => {
        this.ui.closeSpinner();
        this.ui.manageError(error, '[ERROR] Salvataggio metadata sessione');
        localStorage.removeItem(this.loggedUserInfoLocalStorageKey);
      }
    );
  }

}
