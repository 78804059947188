import { AbstractControl } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 's1-input-text',
  templateUrl: './s1-input-text.component.html',
  styleUrls: ['./s1-input-text.component.scss']
})
export class S1InputText implements OnInit {

  @Input() control: AbstractControl;
  // @Input() control: FormControl;
  @Input() label = '';
  @Input() hasAction = false;
  @Input() placeholder: string = null;
  @Input() hideLabel = false;
  @Input() readonly = false;
  @Input() type = 'text';
  @Input() isValid = true;
  @Input() min = 0;
  @Input() max: number;

  constructor() { }

  ngOnInit(): void { }

  getPlaceholder(): string {
    if (this.placeholder != null) {
      return this.placeholder;
    }
    return '';
  }

  checkIsValid(): boolean {
    return this.control?.valid || !this.control?.touched;
  }

  get ctrl() {
    return this.control as FormControl;
  }

}
