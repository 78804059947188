import { Component, OnInit, Input } from '@angular/core';
import { OrderProcessStatus } from '@app/core/interfaces/order-process.interface';
import { SaleOrderStatus } from '@app/core/interfaces/sale-order.interface';

@Component({
  selector: 'app-order-header-state',
  templateUrl: './order-header-state.component.html',
  styleUrls: ['./order-header-state.component.scss']
})
export class OrderHeaderStateComponent implements OnInit {

  @Input() state: number;
  State = SaleOrderStatus || OrderProcessStatus;

  constructor() { }

  ngOnInit() { }

}
