import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LogLevel, RequestBody } from '@app/core/interfaces/admin.interface';
import { Multiselect, MultiselectItem } from '@app/core/interfaces/core.interface';
import { LabelPlacement } from '@app/core/interfaces/label.interface';
import { LocationInfo } from '@app/core/interfaces/location.interface';
import { PrintLocationFlap, PrintLocationFlapFilter } from '@app/core/interfaces/printer.interface';
import { LoggingService } from '@app/core/services/log.service';
import { MultiselectService } from '@app/core/services/multiselect.service';
import { PrinterService } from '@app/core/services/printer.service';
import { SettingsService } from '@app/core/settings/settings.service';
import { IS1InputSelectItem, S1ButtonType, S1Modal, S1UIService } from '@app/s1';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-label-print',
  templateUrl: './label-print.component.html',
  styleUrls: ['./label-print.component.scss']
})
export class LabelPrintComponent implements OnInit {

  @ViewChild('labelPrintModal') labelPrintModal: S1Modal;

  @Input()
  get locations(): LocationInfo[] {
    return this.labelLocations;
  }
  set locations(locationList: LocationInfo[]) {
    this.labelLocations = locationList;
  }

  @Input() pageReqBody?: RequestBody;

  private labelLocations: LocationInfo[] = [];

  labelIndicatorPlacement: IS1InputSelectItem[] = [
    {
      code: LabelPlacement.NONE,
      label: this.translate.instant('base.none')
    },
    {
      code: LabelPlacement.TOP,
      label: this.translate.instant('base.top')
    },
    {
      code: LabelPlacement.BOTTOM,
      label: this.translate.instant('base.bottom')
    },
    {
      code: LabelPlacement.LEFT,
      label: this.translate.instant('base.left')
    },
    {
      code: LabelPlacement.RIGHT,
      label: this.translate.instant('base.right')
    }
  ];

  S1ButtonType = S1ButtonType;

  labelGroup: FormGroup;

  printers: MultiselectItem[] = [];

  constructor(
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private multiselectService: MultiselectService,
    private logService: LoggingService,
    private ui: S1UIService,
    private settingService: SettingsService,
    private printerService: PrinterService
  ) {
    this.labelGroup = this.formBuilder.group({
      placement: ['NONE', Validators.required],
      printer: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.loadPrinters();
  }

  getTitle(): string {
    return this.locations?.length === 1
      ? this.translate.instant('label_print.title', { barcode: this.locations[0]?.barcode })
      : this.locations?.length === 0
        ? this.translate.instant('label_print.all_title')
        : this.translate.instant('label_print.multiple_title', { number: this.locations?.length });
  }

  open(): void {
    this.labelPrintModal.open();
  }

  resetModalForm(): void {
    this.labelGroup.get('placement').setValue('NONE');
    this.labelGroup.get('printer').setValue(this.printers[0].code);
  }

  saveLabel(): void {
    this.ui.showSpinner();

    // If locations has a length of 0 then all flaps must be downloaded, a specific object is then configured (PrintLocationFlapFilter)
    const itemToPrint: PrintLocationFlap | PrintLocationFlapFilter = this.locations.length === 0
    ? {
      filter: {...this.pageReqBody, filters: {}},
      ...(this.labelGroup.get('placement').value !== LabelPlacement.NONE) && { arrowDirection: this.labelGroup.get('placement').value }
    }
    : {
      idLocations: this.locations.map(l => l.id),
      ...(this.labelGroup.get('placement').value !== LabelPlacement.NONE) && { arrowDirection: this.labelGroup.get('placement').value }
    };

    this.printerService.downloadLocationFlap(itemToPrint).subscribe(
      (response) => {
        this.logService.log('[SUCCESS] Download pdf flap locazioni', response, LogLevel.DEBUG);
        this.ui.closeSpinner();
        this.labelPrintModal.close();
        this.ui.showSuccessToast('s1.toast.download_success');
        const fileURL = URL.createObjectURL(response);
        window.open(fileURL, '_blank');
      },
      (error) => {
        this.logService.log('[ERROR] Download pdf flap locazioni', error, LogLevel.DEBUG);
        this.ui.closeSpinner();
        this.ui.showErrorToast(this.settingService.manageErrorMsg(error));
      }
    );
  }

  printLabel(): void {
    this.ui.showSpinner();

    // If locations has a length of 0 then all flaps must be printed, a specific object is then configured (PrintLocationFlapFilter)
    const itemToPrint: PrintLocationFlap | PrintLocationFlapFilter = this.locations.length === 0
    ? {
      filter: {...this.pageReqBody, filters: {}},
      idPrinter: this.labelGroup.get('printer').value,
      ...(this.labelGroup.get('placement').value !== LabelPlacement.NONE) && { arrowDirection: this.labelGroup.get('placement').value }
    } : {
      idLocations: this.locations.map(l => l.id),
      idPrinter: this.labelGroup.get('printer').value,
      ...(this.labelGroup.get('placement').value !== LabelPlacement.NONE) && { arrowDirection: this.labelGroup.get('placement').value }
    };

    this.printerService.printLocationFlap(itemToPrint).subscribe(
      (response) => {
        this.logService.log('[SUCCESS] Stampa pdf flap locazioni', response, LogLevel.DEBUG);
        this.ui.closeSpinner();
        this.labelPrintModal.close();
        this.ui.showSuccessToast('s1.toast.print_success');
      },
      (error) => {
        this.logService.log('[ERROR] Stampa pdf flap locazioni', error, LogLevel.DEBUG);
        this.ui.closeSpinner();
        this.ui.showErrorToast(this.settingService.manageErrorMsg(error));
      }
    );
  }

  // ---------- PRIVATE METHODS --------- //

  private loadPrinters(): void {
    this.multiselectService.loadMultiselect(Multiselect.PRINTER).subscribe(
      (response) => {
        this.logService.log('[SUCCESS] Caricamento multiselect', response, LogLevel.DEBUG);
        const { data } = response;
        this.printers = this.multiselectService.getItemsFromData(data);
        this.labelGroup.get('printer').setValue(this.printers[0].code);
      },
      (error) => {
        this.logService.log('[ERROR] Caricamento multiselect', error, LogLevel.DEBUG);
        this.ui.showErrorToast(this.settingService.manageErrorMsg(error));
      }
    );
  }

}
