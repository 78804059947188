import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { LoggingService } from './log.service';
import { throwError } from 'rxjs';
import { SettingsService } from '../settings/settings.service';



@Injectable({ providedIn: 'root' })
export class RestfulService {

  public baseurl = environment.restBaseUrl;

  constructor(
    private http: HttpClient,
    private logger: LoggingService,
    private router: Router,
    private settings: SettingsService,
    private authService: AuthService,
  ) { }

  // POST http request
  post(endPoint, reqBody) {
    const url = this.baseurl + endPoint;
    return this.http
      .post<any[]>(url, reqBody, this.getRequestOptionArgs())
      .pipe(map(
        (response: HttpResponse<any>) => response
      ), catchError((errorResponse: HttpErrorResponse) => {
        const res: any = errorResponse.error;
        if (res.outcome) {
          if (res.outcome.code === "0005" || res.outcome.code === "0007") {
            this.settings.sessionExpired();
          }
        }
        return throwError(errorResponse.message);
      }
      ));
  }

  // POST http request
  post_downloadFile(endPoint, reqBody, contentType: string = 'application/json') {
    let url = this.baseurl + endPoint;
    return this.http
      .post<any[]>(url, reqBody, this.getRequestOptionArgs_downlodFile(contentType))
      .pipe(map(
        (response: HttpResponse<any>) => response
      ), catchError((errorResponse: HttpErrorResponse) => {
        const res: any = errorResponse.error;
        if (res.outcome) {
          if (res.outcome.code === "0005" || res.outcome.code === "0007") {
            this.settings.sessionExpired();
          }
        }
        return throwError(errorResponse.message);
      }
      ));
  }

  //GET http request
  get(endPoint) {
    const url = this.baseurl + endPoint;
    return this.http
      .get<any[]>(url, this.getRequestOptionArgs())
      .pipe(map(
        (response: HttpResponse<any>) => response
      ), catchError((errorResponse: HttpErrorResponse) => {
        const res: any = errorResponse.error;
        if (res.outcome) {
          if (res.outcome.code === "0005" || res.outcome.code === "0007") {
            this.settings.sessionExpired();
          }
        }
        return throwError(errorResponse.message);
      }
      ));
  }

  // GET http request
  get_downloadFile(endPoint, contentType: string = 'application/json') {
    let url = this.baseurl + endPoint;
    return this.http
      .get<any[]>(url, this.getRequestOptionArgs_downlodFile(contentType))
      .pipe(map(
        (response: HttpResponse<any>) => response
      ), catchError((errorResponse: HttpErrorResponse) => {
        const res: any = errorResponse.error;
        return throwError(errorResponse.message);
      }
      ));
  }

  // PUT http request
  put(endPoint, reqBody) {
    const url = this.baseurl + endPoint;
    return this.http
      .put<any[]>(url, reqBody, this.getRequestOptionArgs())
      .pipe(map(
        (response: HttpResponse<any>) => response
      ), catchError((errorResponse: HttpErrorResponse) => {
        const res: any = errorResponse.error;
        if (res.outcome) {
          if (res.outcome.code === "0005" || res.outcome.code === "0007") {
            this.settings.sessionExpired();
          }
        }
        return throwError(errorResponse.message);
      }
      ));
  }

  // DELETE http request
  delete(endPoint) {
    const url = this.baseurl + endPoint;
    return this.http
      .delete<any[]>(url, this.getRequestOptionArgs())
      .pipe(map(
        (response: HttpResponse<any>) => response
      ), catchError((errorResponse: HttpErrorResponse) => {
        const res: any = errorResponse.error;
        if (res.outcome) {
          if (res.outcome.code === "0005" || res.outcome.code === "0007") {
            this.settings.sessionExpired();
          }
        }
        return throwError(errorResponse.message);
      }
      ));
  }
  
  getRequestOptionArgs(): any {
    const loggedUserInfo = this.authService.getLoggedUserInfoFromLocalStorage();
    let token = null;
    if (loggedUserInfo) {
      token = loggedUserInfo.token;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Auth-Token': token != null ? token : ''
      })
    };
    return httpOptions;
  }

  getRequestOptionArgs_downlodFile(contentType: string): any {
    const loggedUserInfo = this.authService.getLoggedUserInfoFromLocalStorage();
    let token = null;
    if (loggedUserInfo) {
      token = loggedUserInfo.token;
    }
    const httpOptions = {
      responseType: "blob",
      headers: new HttpHeaders({
        'Content-Type': contentType,
        'X-Auth-Token': token != null ? token : ''
      })
    };
    return httpOptions;
  }
  
}

