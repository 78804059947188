import { Component, Input, OnInit } from '@angular/core';
import { Differential } from '@app/core/interfaces/differential.interface';

@Component({
  selector: 'app-differential-state',
  templateUrl: './differential-state.component.html',
  styleUrls: ['./differential-state.component.scss']
})
export class DifferentialStateComponent implements OnInit {

  @Input() differential: Differential = null;
  @Input() showColour: boolean = true;
  @Input() showTitle: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

}
