import { Ddt } from "./ddt.interface";

export enum DockAction {
  ASSIGN = "ASSIGN",
  EDIT = "EDIT",
  VIEW = "VIEW",
  MOVE = "MOVE",
  FREE = "FREE"
}

export enum DockState {
  ALL = -2,
  ALL_ASSIGNED = -1,
  COMPLETED = 0,
  NOT_STARTED = 1,
  IN_PROGRESS = 2,
  NOT_ASSIGNED = 3,
}

export interface Dock {
  barcode?: string;
  dockDdts?: Ddt[];
  dockState?: number;
  id?: number;
  startDate?: number;
  state?: number;
}