import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';

import { S1Module  } from '../s1/s1.module';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DndModule } from 'ng2-dnd';
import { ClipboardModule } from 'ngx-clipboard';

import { FlotDirective } from './directives/flot/flot.directive';
import { SparklineDirective } from './directives/sparkline/sparkline.directive';
import { EasypiechartDirective } from './directives/easypiechart/easypiechart.directive';
import { ColorsService } from './colors/colors.service';
import { CheckallDirective } from './directives/checkall/checkall.directive';
import { VectormapDirective } from './directives/vectormap/vectormap.directive';
import { NowDirective } from './directives/now/now.directive';
import { ScrollableDirective } from './directives/scrollable/scrollable.directive';
import { JqcloudDirective } from './directives/jqcloud/jqcloud.directive';

import {
    StateComponent,
    SortableComponent,
    FormHeaderComponent,
    JobStateComponent,
    SortHeaderComponent,
    DockStateComponent,
    DdtStateComponent,
    DifferentialStateComponent,
    DifferentialInputSelectComponent,
    InputListComponent,
    DifferentialSortHeaderComponent,

    MinuteSecondsPipe,
} from './index';
import { LabelPrintComponent } from './components/label-print/label-print.component';
import { OrderMissionStateComponent } from './components/order-mission-state/order-mission-state.component';
import { OrderHeaderStateComponent } from './components/order-header-state/order-header-state.component';
import { OrderUdmStateComponent } from './components/order-udm-state/order-udm-state.component';
import { PhotoGalleryComponent } from './components/photo-gallery/photo-gallery.component';
import { FreshActivityStatusComponent } from './components/fresh-activity-status/fresh-activity-status.component';

// https://angular.io/styleguide#!#04-10
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        AccordionModule.forRoot(),
        AlertModule.forRoot(),
        ButtonsModule.forRoot(),
        CarouselModule.forRoot(),
        CollapseModule.forRoot(),
        DatepickerModule.forRoot(),
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        ModalModule.forRoot(),
        PaginationModule.forRoot(),
        ProgressbarModule.forRoot(),
        RatingModule.forRoot(),
        TabsModule.forRoot(),
        TimepickerModule.forRoot(),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        TypeaheadModule.forRoot(),
        ToastrModule.forRoot(),
        DndModule.forRoot(),
        ClipboardModule,
        S1Module.forRoot()
    ],
    providers: [
        ColorsService
    ],
    declarations: [
        FlotDirective,
        SparklineDirective,
        EasypiechartDirective,
        CheckallDirective,
        VectormapDirective,
        NowDirective,
        ScrollableDirective,
        JqcloudDirective,
        StateComponent,
        SortableComponent,
        FormHeaderComponent,
        JobStateComponent,
        SortHeaderComponent,
        DockStateComponent,
        DdtStateComponent,
        DifferentialStateComponent,
        DifferentialInputSelectComponent,
        InputListComponent,
        DifferentialSortHeaderComponent,
        LabelPrintComponent,
        OrderMissionStateComponent,
        OrderHeaderStateComponent,
        OrderUdmStateComponent,
        PhotoGalleryComponent,
        MinuteSecondsPipe,
        FreshActivityStatusComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        RouterModule,
        AccordionModule,
        AlertModule,
        ButtonsModule,
        CarouselModule,
        CollapseModule,
        DatepickerModule,
        BsDatepickerModule,
        BsDropdownModule,
        ModalModule,
        PaginationModule,
        ProgressbarModule,
        RatingModule,
        TabsModule,
        TimepickerModule,
        TooltipModule,
        PopoverModule,
        TypeaheadModule,
        ToastrModule,
        DndModule,
        ClipboardModule,
        FlotDirective,
        SparklineDirective,
        EasypiechartDirective,
        CheckallDirective,
        VectormapDirective,
        NowDirective,
        ScrollableDirective,
        JqcloudDirective,
        S1Module,
        StateComponent,
        SortableComponent,
        FormHeaderComponent,
        JobStateComponent,
        SortHeaderComponent,
        DockStateComponent,
        DdtStateComponent,
        DifferentialStateComponent,
        DifferentialInputSelectComponent,
        InputListComponent,
        DifferentialSortHeaderComponent,
        LabelPrintComponent,
        OrderMissionStateComponent,
        OrderHeaderStateComponent,
        OrderUdmStateComponent,
        PhotoGalleryComponent,
        MinuteSecondsPipe,
        FreshActivityStatusComponent
    ]
})

// https://github.com/ocombe/ng2-translate/issues/209
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule
        };
    }
}
