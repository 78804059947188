import { Direction } from '@app/core/interfaces/core.interface';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Differential } from '@app/core/interfaces/differential.interface';

@Component({
  selector: 'app-differential-sort-header',
  templateUrl: './differential-sort-header.component.html',
  styleUrls: ['./differential-sort-header.component.scss']
})
export class DifferentialSortHeaderComponent implements OnInit {

  @Input() id: string = '';
  @Input() direction: Direction = Direction.NULL;
  @Input() differential: Differential = null;
  @Input() showColour: boolean = true;
  @Input() showTitle: boolean = false;
  @Output() onSort: EventEmitter<any> = new EventEmitter<any>()

  constructor() { }

  ngOnInit(): void {
  }

  toggle(): void {
    switch (this.direction) {
      case Direction.ASC:
        this.direction = Direction.DESC;
        break;
      case Direction.DESC:
        this.direction = Direction.NULL;
        break;
      default:
        this.direction = Direction.ASC;
        break;
    }
    this.onSort.emit(this.direction);
  }

  getIconByDirection(): string {
    switch (this.direction) {
      case Direction.ASC:
        return 'fa-sort-up';
      case Direction.DESC:
        return 'fa-sort-down';
      default:
        return 'fa-sort';
    }
  }

}
