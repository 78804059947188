import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 's1-state',
  templateUrl: './s1-state.component.html',
  styleUrls: ['./s1-state.component.scss']
})
export class S1State implements OnInit {

  @Input() state: number;
  @Input() clickable = false;

  @Output() stateChange = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  onClick(): void {
    this.stateChange.emit();
  }

}
