import { CodeName } from "./core.interface";
import { ItemRegistryBase } from "./item.interface";
import { MissionDetails } from "./putaway-missions.interface";
import { User } from "./user.interface";

export enum SaleOrderAction {
    NEW = 'NEW',
    EDIT = 'EDIT',
    VIEW = 'VIEW',
    DELETE = 'DELETE',
    SEARCH_ITEMS = 'SEARCH_ITEMS'
}

export enum SaleOrderStatus {
    ALL = -1,
    WITH_ANOMALIES = -2,
    NEW = 0,
    ACTIVE = 5,
    READY = 10,
    IN_PROGRESS = 20,
    ON_PAUSE = 30,
    COMPLETED = 90,
    CONCLUDED = 95,
    CANCELLED = 99
}

export enum OrderMissionStatus {
    TODO = 20,
    IN_PROGRESS = 50,
    PICKING = 52,
    COMPLETED = 90,
    CANCELLED = 99
}

export enum SaleOrderException {
    PICK_MOB_QTY_NA = 4, // Not enough
    PICK_MOB_BAD_PROD = 5, // Ruined goods
    PICK_UDM_NOT_FOUND = 6, // Pallet not found
    PICK_ITEM_NOT_FOUND = 7 // Item not found
}

export enum SaleOrderNoteField {
    AP = 'AP',
    CPR = 'CPR',
    EPAL = 'EPAL',
    IND = 'IND',
    ALTRO = 'ALTRO',
    AT = 'AT'
}

export interface Availability {
    amount?: number;
    total?: number;
    errors?: number[];
}

export interface Anomaly {
    productName?: string;
    barcode?: string;
    packages?: number;
    pieces?: number;
    gross?: number;
    net?: number;
    job?: string;
    operator?: string;
    packagingDate?: number;
}

export interface SaleOrder {
    id?: number;
    orderNo?: string;
    salesType?: string;
	site?: CodeName;
    customerCode?: string;
    customerBusinessName?: string;
    deliveredParcels?: number;
    deliveredPallets?: number;
    deliveredPieces?: number;
    orderedParcels?: number;
    orderedPallets?: number;
    orderedPieces?: number;
    orderedNetWeight?: number;
    orderedGrossWeight?: number;
    deliveredNetWeight?: number;
    deliveredGrossWeight?: number;
    progress?: number;
    status?: string;
    loadDate?: number;
    deliveryDate?: number;
    carrierDesc?: string;
    state?: SaleOrderState;
    note?: string;
    hasAnomaly?: boolean;
    priority?: number;
    dataChanged?: boolean;
}

export interface SaleOrderHeader {
    dailyDeliveredParcels?: number;
    dailyDeliveredPieces?: number;
    dailyDeliveredPallets?: number;
    dailyOrderedParcels?: number;
    dailyOrderedPieces?: number;
    dailyOrderedPallets?: number;
    loadDate?: number;
    orders?: SaleOrder[];
    total?: number;
}

export interface SaleOrderDetail {
    orderLineNo?: number;
    deliveredGrossWeight?: number;
    deliveredNetWeight?: number;
    deliveredPallets?: number;
    deliveredParcels?: number;
    deliveredPieces?: number;
    id?: number;
    itemGroupCode?: string;
    itemGroupDesc?: string;
    itemCode?: string;
    itemDescription?: string;
    missionDetails?: MissionDetails[];
    numOfActiveMissions?: number;
    order?: SaleOrder;
    orderedGrossWeight?: number;
    orderedNetWeight?: number;
    orderedPallets?: number;
    orderedParcels?: number;
    orderedPieces?: number;
    productSpecies?: ItemRegistryBase;
    productOrigin?: ItemRegistryBase;
    productPackaging?: ItemRegistryBase;
    productPackagingLine?: ItemRegistryBase;
    productMaterial?: ItemRegistryBase;
    productPack?: ItemRegistryBase;
    productPackagingType?: ItemRegistryBase;
    productTypology?: ItemRegistryBase;
    productSize?: ItemRegistryBase;
    productCalibre?: ItemRegistryBase;
    productVariety?: ItemRegistryBase;
    productQuality?: ItemRegistryBase;
    progress?: number;
    status?: string;
    hasAnomaly?: boolean;
    processingOrderNo?: string;
    freshArrival?: boolean;
}

export interface SaleOrderState {
    value?: number;
	name?: string;
}

export interface SaleOrderShippingLine {
    idOrderSaleDetail?: number;
    barcode?: string;
    parcels?: number;
}

export interface SaleOrderShippedHistory {
    id: number
    idOrderSaleDetail: number
    orderNo: string
    orderLineNo: number
    barcode: string
    flagControlli: boolean
    parcels: number
    status: string
    error?: string
    tsCreation: number
}

export interface SaleOrderFreshActivity {
    id: number,
    taskIdentifier: string,
    tsCreation: number,
    tsScheduled: number,
    tsExecution?: number
    status: SaleOrderFreshActivityStatus,
    orderSaleDetail: SaleOrderDetail
    user: User,
    retrySuccess?: boolean
}

export enum SaleOrderFreshActivityStatus {
    SCHEDULED = "SCHEDULED",
    EXECUTED = "EXECUTED",
    ERROR = "ERROR",
    CANCELED = "CANCELED"
}