import { ServerJobState } from "./core.interface";

export enum VendorAction {
  
}

export enum VendorStatus {
  WITHDRAWN = "withdrawn",
  DEPLOYED = "deployed",
  ALL = "all",
}

export interface Vendor {
  id?: number;
  code?: string;
  businessName?: string;
  status?: string;
  idLastJob?: number;
}