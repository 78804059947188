import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { IS1InputSelectItem } from '@app/s1/components/s1-input-select/s1-input-select.component';

@Component({
  selector: 'app-differential-input-select',
  templateUrl: './differential-input-select.component.html',
  styleUrls: ['./differential-input-select.component.scss']
})
export class DifferentialInputSelectComponent implements OnInit {

  @Input() control: AbstractControl;
  @Input() label: string = '';
  @Input() readonly: boolean = false;
  @Input() multiple: boolean = false;
  @Input() clearable: boolean = true;
  @Input() itemsList: IS1InputSelectItem[];
  @Input() bindValue: string = 'code';
  @Input() showColour: boolean = true;
  @Input() showTitle: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  get ctrl() {
    return this.control as FormControl;
  }

}
