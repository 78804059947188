const config = require('../assets/config/environment-config.json');
// const envKey = 'local';
const envKey = 'staging';
// const envKey = 'testWms';

export const environment = {
  production: config[envKey].production,
  version: config.versionCode,
  restBaseUrl: config[envKey].baseUrl,
  rows: 50
};