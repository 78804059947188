import { Routes } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { ActivateComponent } from './pages/activate/activate.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { LockComponent } from './pages/lock/lock.component';
import { LoginComponent } from './pages/login/login.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { RecoverComponent } from './pages/recover/recover.component';
import { RegisterComponent } from './pages/register/register.component';


export const routes: Routes = [

    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: 'home', loadChildren: () => import('./home/home.module')
                .then(m => m.HomeModule) },
            { path: 'user', loadChildren: () => import('./users/users.module')
                .then(m => m.UsersModule) },
            { path: 'location', loadChildren: () => import('./location/location.module')
                .then(m => m.LocationModule) },
            { path: 'zone', loadChildren: () => import('./zone/zone.module')
                .then(m => m.ZoneModule) },
            { path: 'registry', loadChildren: () => import('./registry/registry.module')
                .then(m => m.RegistryModule) },
            { path: 'settings', loadChildren: () => import('./settings/settings.module')
                .then(m => m.SettingsModule) },
            { path: 'printers', loadChildren: () => import('./printers/printers.module')
                .then(m => m.PrintersModule) },
            { path: 'products', loadChildren: () => import('./products/products.module')
                .then(m => m.ProductsModule) },
            { path: 'report', loadChildren: () => import('./report/report.module')
                .then(m => m.ReportModule) },
            { path: 'orders', loadChildren: () => import('./order/order.module')
                .then(m => m.OrderModule) },
            { path: 'docks', loadChildren: () => import('./dock/dock.module')
                .then(m => m.DockModule) },
            { path: 'ddt', loadChildren: () => import('./ddt/ddt.module')
                .then(m => m.DdtModule) },
            { path: 'receiving', loadChildren: () => import('./receiving/receiving.module')
                .then(m => m.ReceivingModule) },
            { path: 'product-order', loadChildren: () => import('./product-order/product-order.module')
                .then(m => m.ProductOrderModule) },
            { path: 'survey-templates', loadChildren: () => import('./survey-template/survey-template.module')
                .then(m => m.SurveyTemplateModule) },
            { path: 'survey', loadChildren: () => import('./survey/survey.module')
                .then(m => m.SurveyModule) },
            { path: 'qc-checks', loadChildren: () => import('./qc-check/qc-check.module')
                .then(m => m.QCCheckModule) },
            { path: 'udm', loadChildren: () => import('./udm/udm.module')
                .then(m => m.QCCheckModule) },
            { path: 'putaway-udms', loadChildren: () => import('./putaway/putaway-udm/putaway-udm.module')
                .then(m => m.PutawayUdmModule) },
            { path: 'putaway-rules', loadChildren: () => import('./putaway/putaway-rules/putaway-rules.module')
                .then(m => m.PutawayRulesModule) },
            { path: 'putaway-missions', loadChildren: () => import('./putaway/putaway-missions/putaway-missions.module')
                .then(m => m.PutawayMissionsModule) },
            { path: 'product-stock', loadChildren: () => import('./putaway/product-stock/product-stock.module')
                .then(m => m.ProductStockModule) },
            { path: 'location-stock', loadChildren: () => import('./putaway/location-stock/location-stock.module')
                .then(m => m.LocationStockModule) },
            { path: 'udm-stock', loadChildren: () => import('./putaway/udm-stock/udm-stock.module')
                .then(m => m.UdmStockModule) },
            { path: 'sale-orders', loadChildren: () => import('./shipping/sale-orders/sale-orders.module')
                .then(m => m.SaleOrdersModule) },
            { path: 'picking-check', loadChildren: () => import('./shipping/picking-check/picking-check.module')
                .then(m => m.PickingCheckModule) },
            { path: 'processing-orders', loadChildren: () => import('./processing/processing-orders/processing-orders.module')
                .then(m => m.ProcessingOrdersModule) },
            { path: 'processing-check', loadChildren: () => import('./processing/processing-check/processing-check.module')
                .then(m => m.ProcessingCheckModule) },
        ]
    },

    // Not lazy-loaded routes
    { path: 'login', component: LoginComponent },
    { path: 'login/:id', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'recover', component: RecoverComponent },
    { path: 'activate/:id', component: ActivateComponent },
    { path: 'lock', component: LockComponent },
    { path: 'maintenance', component: MaintenanceComponent },
    { path: '404', component: Error404Component },
    { path: '500', component: Error500Component },

    // Not found
    { path: '**', redirectTo: 'home' }

];
