import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { QCCheckAction, QCCheckPreviousPage } from '../interfaces/qc-check.interface';
import { Udm, UdmCheck, UdmBlockAssign, UdmStockUpdate, UdmStockCorrection, UdmProductPlatformUpdate, UdmReturnedRequest } from '../interfaces/udm.interface';
import { LoggingService } from '@app/core/services/log.service';
import { RequestBody } from '../interfaces/admin.interface';
import * as ENDPOINT from "../constants/endPoint.js";
import { getEndPointWithParams } from "../../../assets/js/util.js";
import { SettingsService } from '../settings/settings.service';
import { RestfulService } from './restful.service';

@Injectable({ providedIn: "root" })
export class QCCheckService {

  typeAction: QCCheckAction;
  itemToEdit: Udm = null;
  qcCheckManagementPreviousPage: QCCheckPreviousPage;

  constructor(
    private logger: LoggingService,
    private settings: SettingsService,
    private restful: RestfulService,
  ) { }

  /* GETTER AND SETTER TYPE ACTION */
  setTypeAction(typeAction: QCCheckAction): void {
    this.typeAction = typeAction;
  }
  getTypeAction(): QCCheckAction {
    return this.typeAction;
  }

  /* GETTER AND SETTER ITEM TO EDIT */
  setItemToEdit(item: Udm): void {
    this.itemToEdit = item;
  }
  getItemToEdit(): Udm {
    return this.itemToEdit;
  }

  /* GETTER AND SETTER QC CHECK MANAGEMENT PREVIOUS PAGE */
  setQCCheckManagementPreviousPage(previousPage: QCCheckPreviousPage): void {
    this.qcCheckManagementPreviousPage = previousPage;
  }
  getQCCheckManagementPreviousPage(): QCCheckPreviousPage {
    return this.qcCheckManagementPreviousPage;
  }

  /* ACTIONS */
  elementList(reqBody: RequestBody): Observable<any> {
    const endPoint = ENDPOINT.QUALITY_CONTROL_UDM_ALL;
    return this.post(endPoint, reqBody);
  }

  thresholdVerifyList(reqBody: RequestBody): Observable<any> {
    const endPoint = ENDPOINT.QUALITY_CONTROL_UDM_THRESHOLD_VERIFY_ALL;
    return this.post(endPoint, reqBody);
  }

  loadElementDetail(idElement: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.QUALITY_CONTROL_UDM, idElement);
    return this.get(endPoint);
  }

  checkList(reqBody: RequestBody): Observable<any> {
    const endPoint = ENDPOINT.QUALITY_CONTROL_UDM_CHECK_ALL;
    return this.post(endPoint, reqBody);
  }

  loadCheckDetail(idElement: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.QUALITY_CONTROL_UDM_CHECK, idElement);
    return this.get(endPoint);
  }

  customerList(idElement: number, reqBody: RequestBody): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.QUALITY_CONTROL_UDM_CUSTOMERS, idElement);
    return this.post(endPoint, reqBody);
  }

  blockList(idUdm: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.QUALITY_CONTROL_UDM_BLOCK, idUdm);
    return this.get(endPoint);
  }

  blockGroupedList(idUdm: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.QUALITY_CONTROL_UDM_BLOCK_GROUPED, idUdm);
    return this.get(endPoint);
  }

  udmBlockList(): Observable<any> {
    const endPoint = ENDPOINT.QUALITY_CONTROL_UDM_BLOCK_LIST;
    return this.get(endPoint);
  }

  udmBlockGroupedList(): Observable<any> {
    const endPoint = ENDPOINT.QUALITY_CONTROL_UDM_BLOCK_LIST_GROUPED;
    return this.get(endPoint);
  }

  fileList(idElement: number, queryParams: string = null): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.QUALITY_CONTROL_UDM_FILE_ALL, idElement) +
      (queryParams ? `?${queryParams}` : '');
    return this.get(endPoint);
  }
  
  /* Azioni */
  downloadUdmsReport(reqBody: RequestBody): Observable<any> {
    const endPoint = ENDPOINT.QUALITY_CONTROL_UDM_EXPORT_CSV;
    return this.postDownload(endPoint, reqBody);
  }

  downloadUdmThresholdVerifysReport(reqBody: RequestBody): Observable<any> {
    const endPoint = ENDPOINT.QUALITY_CONTROL_UDM_THRESHOLD_VERIFY_EXPORT_CSV;
    return this.postDownload(endPoint, reqBody);
  }

  syncDifferential(idUdm: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.QUALITY_CONTROL_UDM_SYNC_DIFFERENTIAL, idUdm);
    return this.post(endPoint, null);
  }

  getUdmListToBeSynced(referenceDate: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.QUALITY_CONTROL_UDM_ALL_SYNC, referenceDate);
    return this.get(endPoint);
  }

  createCheck(element: UdmCheck): Observable<any> {
    const endPoint = ENDPOINT.QUALITY_CONTROL_UDM_CHECK_CREATE;
    return this.post(endPoint, element);
  }

  assignBlocks(element: UdmBlockAssign): Observable<any> {
    const endPoint = ENDPOINT.QUALITY_CONTROL_UDM_BLOCK_ASSIGN;
    return this.post(endPoint, element);
  }

  downloadFile(idFile: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.QUALITY_CONTROL_UDM_FILE, idFile);
    return this.getDownload(endPoint, 'image/*');
  }

  removeFile(idElement: number, idFile: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.QUALITY_CONTROL_UDM_FILE_DELETE, idElement, idFile);
    return this.delete(endPoint);
  }

  updateStock(element: UdmStockUpdate): Observable<any> {
    const endPoint = ENDPOINT.QUALITY_CONTROL_UDM_STOCK_UPDATE;
    return this.post(endPoint, element);
  }

  saveStockCorrection(element: UdmStockCorrection): Observable<any> {
    const endPoint = ENDPOINT.QUALITY_CONTROL_UDM_STOCK_CORRECTION;
    return this.post(endPoint, element);
  }

  updateProductPlatform(idUdm: number, element: UdmProductPlatformUpdate): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.QUALITY_CONTROL_UDM_PRODUCT_PLATFORM_UPDATE, idUdm);
    return this.put(endPoint, element);
  }

  saveReturnedUdm(element: UdmReturnedRequest): Observable<any> {
    const endPoint = ENDPOINT.QUALITY_CONTROL_UDM_RETURNED;
    return this.post(endPoint, element);
  }

  /* RESTFUL */
  post(endPoint: string, reqBody: any): Observable<any> {
    return new Observable((observer) => {
      this.restful.post(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  postDownload(endPoint: string, reqBody: any): Observable<any> {
    return new Observable((observer) => {
      this.restful.post_downloadFile(endPoint, reqBody, 'application/json').subscribe((response: HttpResponse<any>) => {
        observer.next(response);
      });
    });
  }

  get(endPoint: string): Observable<any> {
    return new Observable((observer) => {
      this.restful.get(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  getDownload(endPoint: string, contentType: string = 'application/json'): Observable<any> {
    return new Observable((observer) => {
      this.restful.get_downloadFile(endPoint, contentType).subscribe((response: HttpResponse<any>) => {
        observer.next(response);
      });
    });
  }

  put(endPoint: string, reqBody: any): Observable<any> {
    return new Observable((observer) => {
      this.restful.put(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  delete(endPoint: string): Observable<any> {
    return new Observable((observer) => {
      this.restful.delete(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  responseHandler(response: HttpResponse<any>, observer: any): void {
    const outcome = response['outcome'];
    if (outcome.success === true) {
      this.logger.log("Service:", "SUCCESS", 200);
      observer.next(response);
    } else {
      this.logger.log("Service:", "FAILURE", 200);
      outcome.message = this.settings.manageErrorMsg(outcome);
      observer.error(outcome);
    }
  }

}