import { Component, Input, OnInit } from '@angular/core';
import { SaleOrderFreshActivityStatus } from '@app/core/interfaces/sale-order.interface';

@Component({
  selector: 'app-fresh-activity-status',
  templateUrl: './fresh-activity-status.component.html',
  styleUrls: ['./fresh-activity-status.component.scss']
})
export class FreshActivityStatusComponent implements OnInit {

  @Input() status: SaleOrderFreshActivityStatus = null;

  State = SaleOrderFreshActivityStatus;

  constructor() { }

  ngOnInit(): void {
  }

}
