import { LoggingService } from '@app/core/services/log.service';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestBody } from '../interfaces/admin.interface';
import * as ENDPOINT from '../constants/endPoint.js';
import { SettingsService } from '../settings/settings.service';
import { RestfulService } from './restful.service';
import { getEndPointWithParams } from '../../../assets/js/util.js';
import { Printer, PrinterAction, PrintFlap, PrintLocationFlap, PrintLocationFlapFilter } from '../interfaces/printer.interface';
import { throwError } from 'rxjs';
import { S1UIService } from '@app/s1';

@Injectable({ providedIn: 'root' })
export class PrinterService {

  typeAction: PrinterAction;
  itemToEdit: Printer = null;

  constructor(
    private logger: LoggingService,
    private settings: SettingsService,
    private restful: RestfulService,
    private ui: S1UIService
  ) { }

  /* GETTER AND SETTER TYPE ACTION */
  setTypeAction(typeAction: PrinterAction): void {
    this.typeAction = typeAction;
  }
  getTypeAction(): PrinterAction {
    return this.typeAction;
  }

  /* GETTER AND SETTER ITEM TO EDIT */
  setItemToEdit(item: Printer): void {
    this.itemToEdit = item;
  }
  getItemToEdit(): Printer {
    return this.itemToEdit;
  }

  /* ACTIONS */
  elementList(reqBody: RequestBody): Observable<any> {
    const endPoint = ENDPOINT.CORE_PRINTER_ALL;
    return this.post(endPoint, reqBody);
  }

  /* Azioni */
  create(printer: Printer): Observable<any> {
    const endPoint = ENDPOINT.CORE_PRINTER_CREATE;
    return this.post(endPoint, printer);
  }

  update(printer: Printer): Observable<any> {
    const endPoint = ENDPOINT.CORE_PRINTER_UPDATE;
    return this.post(endPoint, printer);
  }

  toggleStatus(idPrinter: number): Observable<any> {
    const endPoint = getEndPointWithParams(ENDPOINT.CORE_PRINTER_TOGGLE_STATE, idPrinter);
    return this.put(endPoint, null);
  }

  downloadFlap(printFlap: PrintFlap): Observable<any> {
    const endPoint = ENDPOINT.CORE_PRINTER_DOWNLOAD_FLAP;
    return this.postDownload(endPoint, printFlap);
  }

  printFlap(printFlap: PrintFlap): Observable<any> {
    const endPoint = ENDPOINT.CORE_PRINTER_PRINT_FLAP;
    return this.post(endPoint, printFlap);
  }

  /** In order to download all location flaps the param must contain the page requestBody (without filters)  */
  downloadLocationFlap(locationFlap: PrintLocationFlap | PrintLocationFlapFilter): Observable<any> {
    const endPoint = ENDPOINT.CORE_PRINTER_DOWNLOAD_LOCATION_FLAP;
    return this.postDownload(endPoint, locationFlap);
  }

  /** In order to print all location flaps the param must be the contain requestBody (without filters)  */
  printLocationFlap(locationFlap: PrintLocationFlap | PrintLocationFlapFilter): Observable<any> {
    const endPoint = ENDPOINT.CORE_PRINTER_PRINT_LOCATION_FLAP;
    return this.post(endPoint, locationFlap);
  }

  /* RESTFUL */
  post(endPoint: string, reqBody: any): Observable<any> {
    return new Observable((observer) => {
      this.restful.post(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  postDownload(endPoint: string, reqBody: any): Observable<any> {
    return new Observable((observer) => {
      this.restful.post_downloadFile(endPoint, reqBody, 'application/json').subscribe((response: HttpResponse<any>) => {
        this.responseHandlerDownload(response, observer);
      });
    });
  }

  get(endPoint: string): Observable<any> {
    return new Observable((observer) => {
      this.restful.get(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  put(endPoint: string, reqBody: any): Observable<any> {
    return new Observable((observer) => {
      this.restful.put(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  delete(endPoint: string): Observable<any> {
    return Observable.create((observer) => {
      this.restful.delete(endPoint).subscribe((response: HttpResponse<any>) => {
        this.responseHandler(response, observer);
      });
    });
  }

  responseHandler(response: HttpResponse<any>, observer: any): void {
    const outcome = response['outcome'];
    if (outcome.success === true) {
      this.logger.log('Service:', 'SUCCESS', 200);
      observer.next(response);
    } else {
      this.logger.log('Service:', 'FAILURE', 200);
      outcome.message = this.settings.manageErrorMsg(outcome);
      observer.error(outcome);
    }
  }

  responseHandlerDownload(response: any, observer: any): void {
    if (response.type == 'application/json') {
      let reader = new FileReader();
      reader.onload = (e: Event) => {
        try {
          const res = JSON.parse((<any>e.target).result);
          const outcome = res['outcome'];
          if (outcome.success === true) {
            this.logger.log('Service:', 'SUCCESS', 200);
            observer.next(res);
          } else {
            this.logger.log('Service:', 'FAILURE', 200);
            outcome.message = this.settings.manageErrorMsg(outcome);
            observer.error(outcome);
          }
        } catch (error) {
          this.logger.log('Service:', 'FAILURE', 200);
          return throwError(error.message);
        }
      };
      reader.onerror = (error) => {
        this.logger.log('Service:', 'FAILURE', 200);
        return throwError(error);
      };
      reader.readAsText(response);
    } else {
      this.logger.log('Service:', 'SUCCESS', 200);
      observer.next(response);
    }
  }

}