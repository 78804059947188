import { ItemOrderDetail } from './item.interface';
import { LocationInfo } from "./location.interface";
import { Order } from "./order.interface";

export enum DdtAction {
  EDIT = "edit",
  VIEW = "view",
  DELETE = "delete",
};

export enum DdtStatus {
  ALL = -1,
  OPEN = 0,
  CLOSE = 1,
  WITH_ANOMALY = 2,
}

export interface Ddt {
  actualPackages?: number;
  actualPallets?: number;
  actualPieces?: number;
  dateAssignLocation?: number;
  dateFreeLocation?: number;
  ddtNumber?: string;
  hasAnomaly?: boolean;
  id?: number;
  identifier?: string,
  idOrder?: number,
  lastLocation?: LocationInfo;
  location?: LocationInfo;
  orderNo?: string;
  order?: Order;
  orderPackages?: number;
  orderPallets?: number;
  orderPieces?: number;
  packages?: number;
  pallets?: number;
  pieces?: number;
  product?: ItemOrderDetail;
  receiptDate?: number;
  receiptOrderDate?: number;
  receivedPackages?: number;
  receivedPallets?: number;
  receivedPieces?: number;
  startDate?: number;
  status?: number;
  tsLastModified?: number;
  usernameLastModified?: string,
  vendorBusinessName?: string,
}