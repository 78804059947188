import { DockState } from '@app/core/interfaces/dock.interface';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dock-state',
  templateUrl: './dock-state.component.html',
  styleUrls: ['./dock-state.component.scss']
})
export class DockStateComponent implements OnInit {

  @Input() state: number = null;
  DockState = DockState;

  constructor() { }

  ngOnInit(): void {
  }

}
