import { ConfigurationService } from './../core/services/configuration.service';
import { AuthService } from './../core/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from '../core/settings/settings.service';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

    processing = {
        configurationFile: false,
    };

    constructor(
        private settings: SettingsService,
        private router: Router,
        private authService: AuthService,
        private configurationService: ConfigurationService,
    ) {

        const loggedUserInfo = this.authService.getLoggedUserInfoFromLocalStorage();
        let token = null;
        if (loggedUserInfo) {
            token = loggedUserInfo.token;
        }
        // Check token data
        if (!token) {
            this.settings.sessionExpired();
            this.router.navigate(["/login"]);
        } else {
            this.configurationService.setPermissions(loggedUserInfo.permissions);
            this.configurationService.setConfiguration(loggedUserInfo.configuration);
            this.configurationService.setAdmin(loggedUserInfo.admin);
            // this.loadConfigurationFile();
        }

    }

    ngOnInit() {
    }

    loadConfigurationFile(): void {
        this.processing.configurationFile = true;
        this.configurationService.loadConfigurationFile().subscribe(
            (response) => {
                const { data } = response;
                this.processing.configurationFile = false;
                this.configurationService.setConfigurationFile(JSON.parse(data));
            },
            (error) => {
                // this.toasterService.pop("error", "load config file err", this.settings.manageErrorMsg(error));
            }
        );
    }

}
