import { AbstractControl } from '@angular/forms';
import { Component, Input, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { S1ReadableDatePipe } from '../../pipes/s1-readable-date.pipe';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 's1-input-datepicker',
  templateUrl: './s1-input-datepicker.component.html',
  styleUrls: ['./s1-input-datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class S1InputDatepicker implements AfterViewInit {

  @Input() control: AbstractControl;
  // @Input() control: FormControl;
  @Input() label: string = '';
  @Input() hasAction: boolean = false;
  @Input() readonly: boolean = false;
  @Input() maxDate: Date;
  @Input() minDate: Date;
  @Input() withTimepicker: boolean = false;
  @Input() customConfig: Partial<BsDatepickerConfig> = null;

  dateInputFormat = 'DD/MM/YYYY';
  dateTimeInputFormat = 'DD/MM/YYYY HH:mm';

  bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-dark-blue',
    dateInputFormat: this.dateInputFormat,
    minDate: null,
    maxDate: null,
  }
  
  constructor(private readableDatePipe: S1ReadableDatePipe) {}

  ngAfterViewInit(): void {
    if (this.withTimepicker) {
      this.bsConfig = {
        ...this.bsConfig,
        dateInputFormat: this.dateTimeInputFormat,
        withTimepicker: true,
        initCurrentTime: false
      }
    }

    if (this.customConfig != null) {
      this.bsConfig = {
        ...this.bsConfig,
        ...this.customConfig,
      }
    }
  }

  checkIsValid(): boolean {

    // controllo se è stato impostato il maxDate
    if (this.maxDate) {
      this.bsConfig.maxDate = this.maxDate
    }

    if (this.minDate) {
      this.bsConfig.minDate = this.minDate
    }

    return this.control?.valid || !this.control?.touched;

  }

  readableDate() {
    return this.readableDatePipe.transform(this.control?.value, this.withTimepicker)
  }

  get ctrl() {
    return this.control as FormControl;
  }
  
}