import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TranslatorService {

    private defaultLanguage: string = 'it';
    private languageInUse: string = 'it';

    private availablelangs = [
        { code: 'it', text: 'Italian' },
        { code: 'en', text: 'English' },
    ];

    constructor(public translate: TranslateService) {

        // Default language in caso non ci sia valore nel local storage
        if (!translate.getDefaultLang())
            translate.setDefaultLang(this.defaultLanguage);

        // Setto come lingua in uso quella salvata nel local storage
        this.useLanguage(this.getFavouriteLanguage())

    }

    useLanguage(newLang: string = null) {

        // Controllo che la lingua che si vuole settare sia un codice lingua tra quelli disponibili altrimenti uso quello di default
        if (this.availablelangs.find(lang => lang.code == newLang)) {
            this.languageInUse = newLang
        } else {
            this.languageInUse = this.translate.getDefaultLang()
        }

        // Setto la nuova lingua in uso e la salvo come preferita
        this.translate.use(this.languageInUse)
        this.setFavouriteLanguage(this.languageInUse)

    }

    getLanguageInUse() {
        return this.languageInUse
    }

    getAvailableLanguages() {
        return this.availablelangs;
    }

    getFavouriteLanguage():string {
        return localStorage.getItem("language");
    }

    setFavouriteLanguage(lang: string) {
        localStorage.setItem("language", lang);
    }

}
