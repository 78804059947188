export enum QCCheckAction {
	EDIT = "EDIT",
  VIEW = "VIEW",
	VIEW_PHOTO = "VIEW_PHOTO"
}

export enum QCCheckPreviousPage {
  QC_CHECK_LIST = "QC_CHECK_LIST",
  PUTAWAY_UDM_LIST = "PUTAWAY_UDM_LIST",
}

export enum QCCheckUdmStatus {
  ALL = "ALL",
  WITHOUT_OUTCOME = "WITHOUT_OUTCOME",
  WITHOUT_SURVEY = "WITHOUT_SURVEY",
  WITH_ANOMALY = "WITH_ANOMALY",
  SYNC_FAILED = "SYNC_FAILED",
	L0 = "L0",
}