const headerMain = {
  text: 'Core',
  translate: 'menu.header_main',
  heading: true
};

const Home = {
  text: 'Home',
  translate: 'menu.home',
  link: '/home',
  icon: 'fas fa-home',
};

const Users = {
  text: 'User',
  translate: 'menu.user',
  link: '/user',
  icon: 'fas fa-users',
};

const Location = {
  text: 'Location',
  translate: 'menu.location',
  link: '/location',
  icon: 'fas fa-map-marker-alt',
};

const Zone = {
  text: 'Zone',
  translate: 'menu.zone',
  link: '/zone',
  icon: 'fas fa-th-large',
};

const Registry = {
  text: 'Registries',
  translate: 'menu.registry',
  icon: 'fas fa-cloud-upload-alt',
  submenu: [
    {
      text: 'Vendor',
      translate: 'menu.vendors',
      link: '/registry/vendors',
      icon: 'fas fa-truck',
    },
    {
      text: 'Customer',
      translate: 'menu.customers',
      link: '/registry/customers',
      icon: 'fas fa-store',
    },
    {
      text: 'Item',
      translate: 'menu.items',
      link: '/registry/items',
      icon: 'fas fa-boxes',
      submenu: [
        {
          text: 'Calibre',
          translate: 'menu.calibre',
          link: '/registry/items/calibre',
          icon: 'fas fa-angle-right',
        },
        {
          text: 'Origin',
          translate: 'menu.origin',
          link: '/registry/items/origin',
          icon: 'fas fa-angle-right',
        },
        {
          text: 'Quality',
          translate: 'menu.quality',
          link: '/registry/items/quality',
          icon: 'fas fa-angle-right',
        },
        {
          text: 'Dimension',
          translate: 'menu.size',
          link: '/registry/items/size',
          icon: 'fas fa-angle-right',
        },
        {
          text: 'Specie',
          translate: 'menu.specie',
          link: '/registry/items/specie',
          icon: 'fas fa-angle-right',
        },
        {
          text: 'Variety',
          translate: 'menu.variety',
          link: '/registry/items/variety',
          icon: 'fas fa-angle-right',
        },
        {
          text: 'Typology',
          translate: 'menu.typology',
          link: '/registry/items/typology',
          icon: 'fas fa-angle-right',
        },
        {
          text: 'Processing',
          translate: 'menu.processing',
          link: '/registry/items/processing',
          icon: 'fas fa-angle-right',
        },
        {
          text: 'Platform',
          translate: 'menu.platform',
          link: '/registry/items/platform',
          icon: 'fas fa-angle-right',
        },
      ],
    },
    {
      text: 'Processing types',
      translate: 'menu.processing_types',
      link: '/registry/processing-types',
      icon: 'fas fa-box-open',
    }
  ],
};

const Setting = {
  text: 'Setting',
  translate: 'menu.settings',
  icon: 'fas fa-cog',
  submenu: [
    {
      text: 'Service queue',
      translate: 'menu.service_queue',
      link: '/settings/service-queue',
      icon: 'fas fa-stream',
    },
    {
      text: 'Causal',
      translate: 'menu.causal',
      link: '/settings/causal',
      icon: 'fas fa-comment-dots',
    },
    {
      text: 'Purpose',
      translate: 'menu.purpose',
      link: '/settings/purpose',
      icon: 'fas fa-location-arrow',
    },
    {
      text: 'Mailing list',
      translate: 'menu.mailing_list',
      link: '/settings/mailing-list',
      icon: 'fas fa-envelope',
    },
    {
      text: 'Api call log',
      translate: 'menu.api_call_log',
      link: '/settings/api-call-log',
      icon: 'fas fa-stream',
    },
  ]
};

const Printer = {
  text: 'Printers',
  translate: 'menu.printers',
  link: '/printers',
  icon: 'fas fa-print',
};

const Product = {
  text: 'Products',
  translate: 'menu.products',
  icon: 'fas fa-box',
  submenu: [
    {
      text: 'Thresholds',
      translate: 'menu.thresholds',
      link: '/products/thresholds',
      icon: 'fas fa-clock',
    },
    {
      text: 'TipologyCustomers',
      translate: 'menu.typology_customers',
      link: '/products/typology-customers',
      icon: 'fas fa-angle-right',
    },
  ]
};

const Report = {
  text: 'Report',
  translate: 'menu.report',
  link: '/report',
  icon: 'fas fa-table',
};

const headerInbound = {
  text: 'Receiving',
  translate: 'menu.header_inbound',
  heading: true
};

const Order = {
  text: 'Order',
  translate: 'menu.orders',
  icon: 'fas fa-clipboard-list',
  submenu: [
    {
      text: 'Order',
      translate: 'menu.order_list',
      link: '/orders',
      icon: 'fas fa-clipboard-list',
    },
    {
      text: 'ProductOrder',
      translate: 'menu.product_order',
      link: '/product-order',
      icon: 'fas fa-receipt',
    }
  ]
};

const Dock = {
  text: 'Dock',
  translate: 'menu.dock',
  link: '/docks',
  icon: 'fas fa-warehouse',
};

const Ddt = {
  text: 'Ddt',
  translate: 'menu.ddt',
  link: '/ddt',
  icon: 'fas fa-file-invoice',
};

const Receiving = {
  text: 'Receiving',
  translate: 'menu.receiving',
  link: '/receiving',
  icon: 'fas fa-people-carry',
};

const headerQualityControl = {
  text: 'QualityControl',
  translate: 'menu.header_quality_control',
  heading: true
};

const SurveyTemplate = {
  text: 'SurveyTemplates',
  translate: 'menu.survey_templates',
  link: '/survey-templates',
  icon: 'fas fa-clipboard-check',
};

const QCCheck = {
  text: 'QCChecks',
  translate: 'menu.qc_checks',
  link: '/qc-checks',
  icon: 'fas fa-check-circle',
};

const headerPutaway = {
  text: 'Putaway',
  translate: 'menu.header_putaway',
  heading: true
};

const PutawayUdm = {
  text: 'PutawayUdms',
  translate: 'menu.putaway_udms',
  link: '/putaway-udms',
  icon: 'fas fa-pallet',
};

const PutawayRules = {
  text: 'PutawayRules',
  translate: 'menu.putaway_rules',
  link: '/putaway-rules',
  icon: 'fas fa-balance-scale'
};

const PutawayMissions = {
  text: 'PutawayMissions',
  translate: 'menu.putaway_missions',
  link: '/putaway-missions',
  icon: 'fas fa-rocket'
};

const LocationStock = { // Rimosso dal menù il 11-06-2024
  text: 'LocationStock',
  translate: 'menu.location_stock',
  link: '/location-stock',
  icon: 'fas fa-dolly-flatbed'
};

const UdmStock = {
  text: 'UdmStock',
  translate: 'menu.product_stock',
  link: '/udm-stock',
  icon: 'fas fa-boxes'
};

const headerShipping = {
  text: 'Shipping',
  translate: 'menu.header_shipping',
  heading: true
};

const SaleOrders = {
  text: 'SaleOrders',
  translate: 'menu.sale_orders',
  link: '/sale-orders',
  icon: 'fas fa-money-check-alt'
};

const PickingCheck = {
  text: 'PickingCheck',
  translate: 'menu.picking_check',
  link: '/picking-check',
  icon: 'fas fa-search'
};

const headerProcessing = {
  text: 'Processing',
  translate: 'menu.header_processing',
  heading: true
};

const ProcessingOrders = {
  text: 'ProcessingOrders',
  translate: 'menu.processing_orders',
  link: '/processing-orders',
  icon: 'fas fa-tape'
};

const ProcessingCheck = {
  text: 'ProcessingCheck',
  translate: 'menu.processing_check',
  link: '/processing-check',
  icon: 'fas fa-search'
};

export const menu = [
  headerMain,
  Home,
  Users,
  Location,
  Zone,
  Registry,
  Setting,
  Printer,
  Product,
  Report,
  headerInbound,
  Dock,
  Ddt,
  Order,
  Receiving,
  headerQualityControl,
  SurveyTemplate,
  QCCheck,
  headerPutaway,
  PutawayUdm,
  PutawayRules,
  PutawayMissions,
  UdmStock,
  headerShipping,
  SaleOrders,
  PickingCheck,
  headerProcessing,
  ProcessingOrders,
  ProcessingCheck
];
